import React, { useState, useEffect } from 'react';

import Header from './header-group/HeaderGroupStandard';

import { Paper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  centerchild: {
    display: 'flex',
    width: '95%',
    margin: '20px auto',
  },
  paper: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'justify',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: '700',
    margin: 'auto',
    padding: '15px 0',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.5rem',
    },
  },
  msg: {
    fontSize: '1rem',
    fontWeight: '300',
    margin: 'auto',
    padding: '5px 35px 20px 35px',
    lineHeight: '2rem',
  },
}));

const CompanyInfo = ({ history }) => {
  const classes = useStyles();
  const [headerTitle, setTitle] = useState('');
  const [infoDetail, setInfoDetail] = useState('');

  const linkPath = history.location.pathname;
  let linksplit = linkPath.split('/');
  let infoselected = linksplit[2];

  const setPageDetails = (title, detail) => {
    setTitle(title);
    setInfoDetail(detail);
  };

  useEffect(() => {
    let Title;
    let ContentMsg;

    switch (infoselected) {
      case 'refunds':
        Title = 'Refunds and Returns';
        ContentMsg = `During this period of Covid-19 crisis Payless Super Fresh & Truck Load Store cannot accept returned merchandise for refund or exchange. Reasonable refund or exchange will be provided without presentation of returned merchandise. Call your customer service representative at (670)234-1444  for details.
          `;
        return setPageDetails(Title, ContentMsg);

      case 'payment':
        Title = 'Payment Methods';
        ContentMsg = `We accept Visa, Mastercard and American Express credit cards however we are unable to finalize on line payment and all credit card information will be taken over the phone and transaction finalized at time of delivery or at curbside pickup.
        Delivery is available to those customers we can track physical location via smart phone and GPS tracking, or known location and all other orders will be available via curbside pickup at dedicated parking stalls at Payless Superfresh site. We suggest use of Whatsapp or other location tracking app to allow us to track your physical location. Deliveries and curbside pickup orders will be available within 24 hours from time order is submitted and we will call within 24 hours for delivery instructions.
      `;
        return setPageDetails(Title, ContentMsg);
      case 'privacy':
        Title = 'Privacy Policy';
        ContentMsg = ``;
        return setPageDetails(Title, ContentMsg);
      case 'terms':
        Title = 'Terms & Conditions';
        ContentMsg = `By placing an order on line to utilize home delivery or curbside pickup you confirm purchase of all items selected.  We will communicate with you via phone or email acknowledging receipt of your order including the items and quantities you selected, as well as to notify you of your delivery or pickup date & time.
      We cannot guarantee items you order, or the quantity you order will be in stock and available at the time your order is processed. At the time you complete your order we will require you to provide a valid, accurate and complete Credit Card authorization via telephone and signature at time of delivery or pick up. For the safety of customers and associates, on line orders are credit cards only. Cash, checks, cannot be accepted for on line orders for delivery or pick up.  When you place your order, it will take one business day to process. When you arrive at Payless for curbside pick up you should park in dedicated curbside parking area and call (670)234-1444 to reach a store associate. A store associate will then deliver your order to your vehicle and finalize credit card transaction.    
      `;
        return setPageDetails(Title, ContentMsg);
      default:
        Title = '';
        ContentMsg = '';
        return setPageDetails(Title, ContentMsg);
    }
  }, [linkPath, infoselected]);

  return (
    <>
      <Header />
      <div className={classes.root}>
        <div className={classes.centerchild}>
          <Paper className={classes.paper}>
            <span className={classes.title}>{headerTitle}</span>
            <span className={classes.msg}>{infoDetail}</span>
          </Paper>
        </div>
      </div>
    </>
  );
};

export default CompanyInfo;
