import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper } from '@material-ui/core';

import { resetCheckoutData } from '../store/actions/checkoutAction';

const useStyles = makeStyles((theme) => ({
  layout: {
    display: 'flex',
    width: '90%',
    margin: '100px auto',
    justifyContent: 'center',
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    textTransform: 'inherit',
  },
  typoDesign: {
    margin: '10px',
  },
}));

const Success = () => {
  const classes = useStyles();
  const history = useHistory();
  const transRef = useSelector((state) => state.checkoutData.trans_ref);
  const userInfo = useSelector((state) => state.addressForm.userinfo);
  const dispatch = useDispatch();

  const handleReturn = () => {
    dispatch(resetCheckoutData());
    history.push('/');
  };

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography
            component='h4'
            variant='h4'
            align='center'
            className={classes.typoDesign}
          >
            Successfully Process Transaction# {transRef}
          </Typography>
          <Typography
            variant='body1'
            gutterBottom
            className={classes.typoDesign}
          >
            Hello, {userInfo.cardName}!
          </Typography>
          <Typography className={classes.typoDesign}>
            Thank you for your order with Triple J Online Shop!
          </Typography>
          <div>
            <Button
              variant='contained'
              color='primary'
              className={classes.button}
              onClick={handleReturn}
            >
              Return to Home Page
            </Button>
          </div>
        </Paper>
      </main>
    </React.Fragment>
  );
};

export default Success;
