import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { signIn } from '../../store/actions/authActions';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    height: 'auto',
    // marginTop: '10%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  textField: {
    width: '100%'
  },
  error: {
    width: 'auto',
    margin: 'auto',
    color: 'red'
  }
}));

const SignIn = () => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    showPassword: false
  });

  const auth = useSelector(state => state.firebase.auth);
  const authError = useSelector(state => state.auth.authError);
  const dispatch = useDispatch();

  if (auth.uid) return <Redirect to='/' />;

  const handleLoginSubmit = ev => {
    ev.preventDefault();
    const credential = { email: values.email, password: values.password };
    dispatch(signIn(credential));
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <main className={classes.layout}>
      <Paper className={classes.paper}>
        <form onSubmit={handleLoginSubmit}>
          <Typography variant='h6' gutterBottom>
            Sign In
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl className={classes.textField}>
                <InputLabel htmlFor='Email'>Email</InputLabel>
                <Input
                  required
                  type='email'
                  id='email'
                  label='Email'
                  placeholder='john.smith@example.com'
                  value={values.email}
                  onChange={handleChange('email')}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.textField}>
                <InputLabel>Password</InputLabel>
                <Input
                  required
                  id='password'
                  label='Password'
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  fullWidth
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button type='submit' variant='contained' color='primary'>
                Login
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.error}>
                {authError ? <Typography>{authError}</Typography> : null}
              </div>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </main>
  );
};

export default SignIn;
