import axios from 'axios';

export const InventoryForm = (itemInfo) => {
  // console.log(itemInfo);
  return {
    type: 'INPUT_ITEM',
    payload: itemInfo,
  };
};

export const CancelInventory = (initInfo) => {
  return {
    type: 'CANCEL_ITEM',
    payload: initInfo,
  };
};

//export const deleteItem = (index, getState) => (dispatch) => {
export const AddInventory = (newitem, imgfile) => (dispatch) => {
  dispatch({ type: 'UPLOADING' });

  const fd = new FormData();
  fd.append('image', imgfile, imgfile.name);

  let imgURL;

  try {
    axios
      .post('/inventory/image', fd)
      .then((res) => {
        imgURL = res.data.imageURL;

        let itemData = {
          itemcode: newitem.ItemCode,
          itemname: newitem.ItemName.trim(),
          onhand: parseFloat(newitem.Onhand),
          uom: newitem.UOM,
          sellprice: parseFloat(newitem.SellPrice),
          category: newitem.Category,
          image: imgURL,
          hotdeals: newitem.HotDeals,
          features: newitem.Features,
        };

        return axios.post('/inventory/createitem', itemData);
      })
      .then((res) => {
        console.log(res);
        const resultMsg = res.data.message;
        dispatch({ type: 'UPLOAD_SUCCESS', payload: resultMsg });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: 'ERROR',
          payload: 'Error in processing data to server',
        });
      });
  } catch (error) {
    console.error(error);
    dispatch({ type: 'ERROR', payload: 'Internal Server Error' });
  }
};

export const DeleteInventory = (item) => {
  // Dispatch Delete Post
};
