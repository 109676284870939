import React, { useState } from 'react';
//import styled from 'styled-components';
import currencyFormat from '../../config/currencyFormat';

import defaultImg from '../../images/no-product-image-available.png';

const OrderList = ({ items, count, handleChangeCart, handleDeleteItem }) => {
  const [cartQty, setCartQty] = useState(items.CartQty);
  const [subtotal, setSubtotal] = useState(items.TotalAmount);
  let imgURL;
  imgURL = `https://firebasestorage.googleapis.com/v0/b/triple-j-online.appspot.com/o/${items.ItemCode.trim()}.jpg?alt=media`;

  const handleUp = () => {
    /* console.log('handle up'); */

    let newCartQty = cartQty + 1;
    setCartQty(newCartQty);
    setSubtotal(items.SellPrice * newCartQty);
    let newCartInfo = { index: count, newCartQty };

    handleChangeCart(newCartInfo);
  };

  const handleDown = () => {
    /* console.log('handle down'); */

    if (cartQty !== 1) {
      let newCartQty = cartQty - 1;
      setCartQty(newCartQty);
      setSubtotal(items.SellPrice * newCartQty);

      let newCartInfo = { index: count, newCartQty };
      handleChangeCart(newCartInfo);
    }
  };

  /* const handleDelete = () => {
    handleDeleteItem(count);
  }; */

  const addDefaultImg = (ev) => {
    ev.target.src = defaultImg;
  };

  return (
    <>
      <div className='cart-item-wrapper'>
        <div className='cart-item-img-container'>
          <img
            className='cart-item-img'
            onError={addDefaultImg}
            src={imgURL}
            alt='itemImg'
          />
        </div>
        <div className='cart-item-desc-container'>
          <label className='cart-item-desc'>{items.ItemName}</label>
          <span className='cart-item-itemcode'>#{items.ItemCode}</span>
        </div>
        <div className='cart-item-qty'>
          <button className='cart-item-btn-left' onClick={handleUp}>
            <i className='fa fa-caret-up'></i>
          </button>
          <input type='text' value={cartQty} />
          <button className='cart-item-btn-right' onClick={handleDown}>
            <i className='fa fa-caret-down'></i>
          </button>
        </div>
        <div className='cart-item-label-container'>
          <label className='cart-item-label-price'>
            ${currencyFormat(items.SellPrice)}
          </label>
          <label className='cart-item-label-total'>
            ${currencyFormat(parseFloat(subtotal))}
          </label>
        </div>
      </div>
    </>
  );
};

export default OrderList;
