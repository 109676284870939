import checkOutReducer from './checkOutReducer';
import addFormReducer from './addFormReducer';
import authReducer from './authReducer';
import inventoryReducer from './inventoryReducer';
import transactionReducer from './transactionReducer';
import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

const rootReducer = combineReducers({
  auth: authReducer,
  checkoutData: checkOutReducer,
  addressForm: addFormReducer,
  inventory: inventoryReducer,
  transaction: transactionReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});

export default rootReducer;
