import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

import _ from 'lodash';

import Item from '../components/Item';

// Designs
import { Container, Typography, makeStyles } from '@material-ui/core';

const enhance = compose(
  firestoreConnect((props) => {
    const sortBy = props.selectValue;
    const categoryBy = props.categoryValue;

    let selectedValue = '';
    if (categoryBy === null || categoryBy === 'VIEW ALL') {
      return [
        {
          collection: 'inventory',
          orderBy: sortBy,
        },
      ];
    } else {
      // console.log(categoryBy);
      selectedValue = categoryBy.trim();
      return [
        {
          collection: 'inventory',
          where: ['Category', '==', selectedValue],
          orderBy: sortBy,
        },
      ];
    }
  }),
  connect((state) => ({
    inventory: state.firestore.ordered.inventory,
  }))
);

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    padding: '10px 0',
    backgroundColor: '#fff',
  },
}));

const Category = ({ inventory, findItem, viewAll }) => {
  const classes = useStyles();
  // const auth = useSelector(state => state.firebase.auth);
  // if (!auth.uid) return <Redirect to='/signin' />;
  // const filterSearch = useSelector((state) => state.filterSearch.searchInput);

  let filterData = {};
  if (viewAll) {
    filterData = inventory;
  } else {
    if (findItem.length > 0) {
      filterData = inventory.filter((o) =>
        o.ItemName.includes(findItem.toUpperCase())
      );
    }
  }

  return (
    <React.Fragment>
      <div className='container'>
        <div className='item-views'>
          {_.isEmpty(filterData) ? (
            <Container className={classes.container}>
              <Typography variant='h6'>
                No products were found matching your search.
              </Typography>
            </Container>
          ) : (
            filterData &&
            filterData.map((product, index) => {
              return <Item key={index} product={product} />;
            })
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default enhance(Category);
