import React from 'react';

import { Link } from 'react-router-dom';

import imgLogo from '../../images/PaylessLogo.png';

const HeaderGroupStandard = () => {
  // const handleKeyPress = (e) => {
  //   console.log(e.charCode);
  //   console.log(input);
  //   if (e.charCode === 13) {
  //     dispatch(filterSearch(input));
  //     setInput('');
  //   }
  // };

  return (
    <div className='headergroup-root'>
      <div className='headergroup-img-container'>
        <img src={imgLogo} alt='logo' className='headergroup-logo' />
        <div className='headergroup-img'></div>
      </div>
      <div className='headergroupstandard-btn-container'>
        <Link to='/' className='headergroupstandard-link'>
          <i className='fa fa-arrow-left headergroupstandard-icon'></i>
          <span className='headergroupstandard-link-title'>
            Return to Home Page
          </span>
        </Link>
      </div>
      <div className='headergroup-msgs'>
        <h2 className='headergroup-msg-title'>We Are In This Together</h2>
        <p className='headergroup-msg-subtitle'>• Inafa'maolek • Tipiyeew •</p>
        <p className='headergroup-msg-text'>
          Introducing Saipan’s first online grocery store – shop at your
          convenience from anywhere! Let us help make life a little easier for
          you – browse our inventory, click on items for purchase, add to cart,
          checkout and a friendly customer representative will finalize
          transaction and payment details over the phone - it’s that easy!
        </p>
      </div>
    </div>
  );
};

export default HeaderGroupStandard;
