import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Action Redux
import { loadProfile } from '../store/actions/addressFromAction';

// Designs
import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline, Paper } from '@material-ui/core';

// Components
import PaymentForm from '../components/PaymentForm';
import ReviewCart from '../components/ReviewCart';

const useStyles = makeStyles((theme) => ({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: 'auto',
    justifyContent: 'space-around',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(900 + theme.spacing(2) * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  title: {
    margin: 'auto',
    fontWeight: '500',
    fontSize: '1.3rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
      fontWeight: '700',
    },
  },
}));

const CheckOutAll = ({ history }) => {
  const classes = useStyles();
  const auth = useSelector((state) => state.firebase.auth);
  const customerProfile = useSelector((state) => state.firebase.profile);
  const isLoaded = useSelector((state) => state.firebase.profile.isLoaded);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.uid && isLoaded) {
      dispatch(loadProfile(customerProfile));
    }
  }, [auth, isLoaded, customerProfile, dispatch]);

  return (
    <React.Fragment>
      <CssBaseline>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <h1 className={classes.title}>Review Cart</h1>
            <ReviewCart history={history} />
          </Paper>
          <Paper className={classes.paper}>
            <h1 className={classes.title}>Check Out</h1>
            <PaymentForm history={history} />
          </Paper>
        </main>
      </CssBaseline>
    </React.Fragment>
  );
};

export default CheckOutAll;
