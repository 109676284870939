const initializeState = {
  product: [],
  checkoutproduct: [],
  totalQty: 0,
  totalAmount: 0,
  feeCOD: false,
  processComplete: false,
  success: false,
  trans_ref: '',
  error: false,
  errmsg: '',
  errEmail: false,
};

const checkOutReducer = (state = initializeState, { type, payload }) => {
  switch (type) {
    case 'ADD_TO_CART':
      // console.log('from ADD_TO_CART');
      let totalCart = payload.CartQty + state.totalQty;
      let subTotal = payload.SellPrice * payload.CartQty;
      let totalAmt = parseFloat(state.totalAmount) + parseFloat(subTotal);
      return {
        ...state,
        product: [...state.product, payload],
        totalQty: totalCart,
        totalAmount: parseFloat(totalAmt),
      };

    case 'UPDATE_CART':
      let cartUpdateTotalAmount = 0;
      let cartUpdateTotalQty = 0;
      // console.log('Update cart reducer');
      // console.log(payload);
      payload.forEach((item) => {
        cartUpdateTotalAmount = cartUpdateTotalAmount + item.TotalAmount;
        cartUpdateTotalQty = cartUpdateTotalQty + item.CartQty;
      });
      return {
        ...state,
        product: payload,
        totalQty: cartUpdateTotalQty,
        totalAmount: parseFloat(cartUpdateTotalAmount),
      };

    case 'DELETE_ITEM':
      let cartDeleteTotalAmount = 0;
      let cartDeleteTotalQty = 0;
      payload.forEach((item) => {
        cartDeleteTotalAmount = cartDeleteTotalAmount + item.TotalAmount;
        cartDeleteTotalQty = cartDeleteTotalQty + item.CartQty;
      });
      return {
        ...state,
        product: payload,
        totalQty: cartDeleteTotalQty,
        totalAmount: cartDeleteTotalAmount,
      };

    case 'EMPTY_CART':
      state = initializeState;
      return state;

    case 'ADD_SERVICE_FEE':
      return {
        ...state,
        feeCOD: payload,
      };

    case 'CHECKOUT_COMPLETE':
      // console.log('checkout complete');
      return {
        ...state,
        trans_ref: payload.transRef,
        success: false,
        processComplete: true,
        error: false,
        errmsg: '',
      };

    case 'CHECKOUT_FAILED':
      console.log('Create CheckOut error');
      console.log(payload.err);
      return {
        ...state,
        success: false,
        processComplete: true,
        error: true,
        errmsg: payload.err,
      };

    case 'EMAIL_SUCCESS':
      return {
        ...state,
        product: [],
        checkoutproduct: [],
        totalQty: 0,
        totalAmount: 0,
        feeCOD: false,
        success: true,
        processComplete: true,
        error: false,
        errmsg: '',
        errEmail: false,
      };

    case 'EMAIL_FAILED':
      state = initializeState;
      return {
        ...state,
        success: false,
        processComplete: true,
        errEmail: true,
      };
    case 'RESET':
      state = initializeState;
      return state;
    default:
      return state;
  }
};

export default checkOutReducer;
