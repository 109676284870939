import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import './App.css';
import Home from './Pages/Home';
import ErrorPage from './Pages/Error404';
import AdminPage from './Pages/Admin';
import CheckOut from './Pages/CheckOutAll';
import Footer from './components/Footer';
import Success from './components/Success';
import CompanyInfo from './components/CompanyInfo';
import SignUp from './Pages/SignUpPage';
import SignIn from './Pages/SignInPage';

import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Typography } from '@material-ui/core';
import axios from 'axios';

axios.defaults.baseURL =
  'https://us-central1-triple-j-online.cloudfunctions.net/api';

const stripePromise = loadStripe('PUBLISHABLE_KEY');

function App() {
  const auth = useSelector((state) => state.firebase.auth);
  // if auth is loaded then we render App.
  // But if not then we doesn't render the one.

  const Copyright = () => {
    return (
      <Typography variant='body2' color='textSecondary' align='center'>
        {'© '}
        <a
          href='https://www.triplej.net/'
          target='_blank'
          style={{ color: '#000' }}
        >
          paylesssuperfresh.com
        </a>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  };

  if (auth.isLoaded) {
    return (
      <>
        <Elements stripe={stripePromise}>
          <CssBaseline>
            <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/checkout' component={CheckOut} />
              <Route exact path='/signin' component={SignIn} />
              <Route exact path='/signup' component={SignUp} />
              <Route exact path='/success' component={Success} />
              <Route exact path='/admin' component={AdminPage} />
              <Route exact path='/companyinfo/:info' component={CompanyInfo} />
              <Route component={ErrorPage} />
            </Switch>
            <div className='footer-page'>
              <Footer />
            </div>
            <Copyright />
          </CssBaseline>
        </Elements>
      </>
    );
  } else {
    return null;
  }
}

export default App;
