import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

// Components
import ScrollToTopBtn from '../components/ScrollToTop';
import HeaderGroup from '../components/header-group/HeaderGroup';
// import Header from '../components/Header';
// import Banner from '../components/Banner';
import Category from '../Pages/Category';
import WeDeliverBar from '../components/WeDeliverBar';
// import containerHome from '../components/containerHome';

import styled from 'styled-components';
import { Container, makeStyles } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '20px',
    width: '100%',
    padding: 0,
  },
  banner: {
    paddingTop: '170px',
  },
  middlebar: {
    marginTop: '10px',
  },
  titlecontainer: {
    display: 'flex',
    width: '100%',
    margin: '20px auto',
  },
  title: {
    margin: 'auto',
    color: '#0054a6',
    fontSize: '1rem',
    fontWeight: '900',
    [theme.breakpoints.up('sm')]: {
      fontSize: '3rem',
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  const itemData = useSelector((state) => state.firestore.ordered.inventory);
  const [selectValue, setSelectValue] = useState('ItemName');
  const [selectList, setSelectList] = useState(null);
  const [categoryValue, setCategoryValue] = useState('VIEW ALL');
  const [searchFilter, setSearchFilter] = useState('');
  const [findItem, setFindItem] = useState('');
  const [viewAll, setViewAll] = useState(true);

  useEffect(() => {
    let categoryList = [];
    categoryList = _.uniq(_.map(itemData, 'Category'));
    categoryList.push('VIEW ALL');
    categoryList.sort();
    setSelectList(categoryList);
  }, [itemData]);

  // const auth = useSelector(state => state.firebase.auth);
  // if (!auth.uid) return <Redirect to='/signin' />;

  const handleSearchValue = (ev) => {
    setSearchFilter(ev.target.value);
  };

  const handleSelectChange = (event) => {
    setViewAll(true);
    setSelectValue(event.target.value);
  };

  const handleSelectCategory = (ev) => {
    setViewAll(true);
    setCategoryValue(ev.target.value.trim());
  };

  const handleSearchSubmit = (ev) => {
    ev.preventDefault();
    setViewAll(false);
    setFindItem(searchFilter);
    setSearchFilter('');
  };

  const handleViewAll = () => {
    setViewAll(true);
    setCategoryValue('VIEW ALL');
  };

  return (
    <>
      <HeaderGroup
        handleSearchValue={handleSearchValue}
        searchFilter={searchFilter}
        handleSearchSubmit={handleSearchSubmit}
      />
      <div className={classes.middlebar}>
        <WeDeliverBar
          handleSelectChange={handleSelectChange}
          handleSelectCategory={handleSelectCategory}
          selectValue={selectValue}
          SelectList={selectList}
          categoryValue={categoryValue}
        />
      </div>
      <div className={classes.titlecontainer}>
        <h1 className={classes.title}>BEST SELLERS</h1>
      </div>
      <Container className={classes.container}>
        <Category
          selectValue={selectValue}
          categoryValue={categoryValue}
          findItem={findItem}
          viewAll={viewAll}
        />
        <ViewAllContainer>
          <ViewAllButton onClick={handleViewAll}>VIEW ALL ITEMS</ViewAllButton>
        </ViewAllContainer>
      </Container>
      <ScrollToTopBtn />
      <ToastContainer
        autoClose={1500}
        position={'top-right'}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
      />
    </>
  );
};

const ViewAllContainer = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 10px 0;
  backgroundcolor: #fff;
`;
const ViewAllButton = styled.button`
  font-size: 1rem;
  cursor: pointer;
  border: none;
  background-color: #fff;
  &:hover {
    text-decoration-line: underline;
  }
`;

export default Home;
