import React, { useState, useEffect, useRef } from 'react';
import { useSelector, connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Redirect, useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import currencyFormat from '../config/currencyFormat';
import styled, { css } from 'styled-components';

// Icons
import van from '../images/delivery-van.png';
// import cart from '../images/push-cart.png';

import { makeStyles, Badge } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '40px',
    width: '100%',
    margin: 'auto',
    backgroundColor: '#b28f6c',
    [theme.breakpoints.up('sm')]: {
      height: '70px',
    },
  },
  grouplogo: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      margin: 'auto',
    },
  },
  title: {
    color: '#fff',
    marginLeft: '10px',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  groupbutton: {
    display: 'flex',
    // width: '32%',
    justifyContent: 'flex-end',
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: '50px',
    },
  },
  buttonbar: {
    height: '80%',
    backgroundColor: '#8f8e8f',
    color: '#fff',
    borderRadius: '50px',
    padding: '0 15px',
    border: '1px solid #fff',
    margin: 'auto 5px',
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.1)',
      transition: '0.3s',
    },
  },
  btntitle: {
    color: '#fff',
    fontSize: '0.5rem',
    margin: 'auto',
    paddingRight: '5px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
      fontWeight: '500',
    },
  },
  link: {
    display: 'flex',
  },
  amount: {
    marginLeft: '8px',
    marginTop: 'auto',
    marginBottom: 'auto',
    fontSize: '0.8rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
  },
  badge: {
    alignItems: 'center',
    margin: 'auto',
  },
}));

const enhance = compose(
  firestoreConnect((props) => {
    return [{ collection: 'parameters' }];
  }),
  connect((state) => ({
    parameters: state.firestore.ordered.parameters,
  }))
);

Modal.setAppElement('#root');

const WeDeliverBar = ({
  handleSelectChange,
  handleSelectCategory,
  selectValue,
  SelectList,
  categoryValue,
}) => {
  const classes = useStyles();
  const controls = useRef();
  const history = useHistory();
  const cartCount = useSelector((state) => state.checkoutData.totalQty);
  const cartAmt = useSelector((state) => state.checkoutData.totalAmount);
  const parameters = useSelector((state) => state.firestore.ordered.parameters);
  const [isZero, setIsZero] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContactInfo, setModalContactInfo] = useState(false);
  const [fixedPosition, setFixedPosition] = useState(false);

  useEffect(() => {
    const initialTop = controls.current.getBoundingClientRect().top;

    const handleScroll = () => {
      setFixedPosition(window.scrollY > initialTop);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let moa = 0;
  if (parameters !== undefined) {
    moa = parameters[0].moa;
  }

  const handleClick = () => {
    if (cartAmt >= moa) {
      setIsZero(false);
    } else {
      setIsZero(true);
      setModalIsOpen(true);
    }
  };

  const handleContactClick = () => {
    setModalContactInfo(true);
  };

  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      border: '1px solid #000',
      background: '#143454',
    },
  };

  let subtitle = '';
  let content = '';
  let button = '';
  const afterOpenModal = () => {
    subtitle.style.color = '#fff';
    subtitle.style.height = '20px';
    content.style.marginTop = '30px';
    content.style.marginBottom = '30px';
    content.style.color = '#fff';
    content.style.lineHeight = '25px';
    button.style.height = '40px';
    button.style.width = '100px';
    button.style.borderRadius = '10px';
    button.style.border = '0';
    button.style.cursor = 'pointer';
    button.style.background = '#0054a6';
    button.style.color = '#fff';
  };

  const invalidAmountModal = () => {
    content.style.marginTop = '10px';
    content.style.marginBottom = '30px';
    content.style.color = '#fff';
    content.style.lineHeight = '25px';
    content.style.fontSize = '1rem';
    button.style.height = '40px';
    button.style.width = '100px';
    button.style.borderRadius = '10px';
    button.style.border = '0';
    button.style.cursor = 'pointer';
    button.style.background = '#0054a6';
    button.style.color = '#fff';
  };

  /* let options = categoryList.forEach((data) => (
    <option key={data.id} value={data.id} style={{ color: '#000' }}>
      {data.name}
    </option>
  )); */

  return (
    <>
      <Rootcontainer fixed={fixedPosition} ref={controls}>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => history.push('/checkout')}
          onAfterOpen={invalidAmountModal}
          style={modalStyle}
          contentLabel='Invalid minimum order amount'
        >
          <p ref={(_content) => (content = _content)}>
            We require a minimum order of at least ${currencyFormat(moa)}
          </p>
          <div>
            <button
              ref={(_button) => (button = _button)}
              onClick={() => history.push('/checkout')}
            >
              Continue
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={modalContactInfo}
          onRequestClose={() => setModalContactInfo(false)}
          onAfterOpen={afterOpenModal}
          style={modalStyle}
          contentLabel='Contact Info'
        >
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
            Payless SuperFresh Contact Information
          </h2>
          <p ref={(_content) => (content = _content)}>
            Store Phone# (670)-234-1444 <br />
            Customer Service Email: customercare@paylesssuperfresh.com <br />
            Gen. Manager for Payless: Morgan Carrico <br />
            Email: mcarrico@triplejsaipan.com <br />
          </p>
          <div>
            <button
              ref={(_button) => (button = _button)}
              onClick={() => setModalContactInfo(false)}
            >
              Return
            </button>
          </div>
        </Modal>

        <div id='wedeliverparent' className={classes.container}>
          <div className={classes.grouplogo}>
            <img src={van} alt='van' />
            <h3 className={classes.title}>We Deliver!</h3>
          </div>
          <div className={classes.groupbutton}>
            <button className={classes.buttonbar} onClick={handleContactClick}>
              <span className={classes.btntitle}>Customer Service</span>
              <i className='fa fa-phone-square phone-icon'></i>
            </button>

            <button className={classes.buttonbar} onClick={handleClick}>
              {!isZero ? (
                <Redirect to='/checkout' className={classes.link} />
              ) : null}
              <span className={classes.btntitle}>Check Out</span>
              <Badge
                badgeContent={cartCount}
                color='primary'
                className={classes.badge}
              >
                <ShoppingCartIcon />
              </Badge>
              <span className={classes.amount}>
                $ {currencyFormat(parseFloat(cartAmt))}
              </span>
            </button>
          </div>
        </div>
        <Sortcontainer>
          <Sortcenter>
            <SelectContainer>
              <Selecttag value={categoryValue} onChange={handleSelectCategory}>
                {SelectList &&
                  SelectList.map((x, y) => <Optiontag key={y}>{x}</Optiontag>)}
              </Selecttag>
            </SelectContainer>
            <Sortgroup>
              <SortTitle>SORT BY:</SortTitle>
              <Radiocontent>
                <Radiolabel>
                  <Radioinput
                    type='radio'
                    name='sorting'
                    value='ItemName'
                    checked={selectValue === 'ItemName' ? true : false}
                    onChange={handleSelectChange}
                  />
                  Description
                </Radiolabel>
              </Radiocontent>
              <Radiocontent>
                <Radiolabel>
                  <Radioinput
                    type='radio'
                    name='sorting'
                    value='SellPrice'
                    checked={selectValue === 'SellPrice' ? true : false}
                    onChange={handleSelectChange}
                  />
                  Price
                </Radiolabel>
              </Radiocontent>
              <Radiocontent>
                <Radiolabel>
                  <Radioinput
                    type='radio'
                    name='sorting'
                    value='UOM'
                    checked={selectValue === 'UOM' ? true : false}
                    onChange={handleSelectChange}
                  />
                  Unit Measure
                </Radiolabel>
              </Radiocontent>
            </Sortgroup>
          </Sortcenter>
        </Sortcontainer>
      </Rootcontainer>
      {fixedPosition && <Placeholder />}
    </>
  );
};

const SelectContainer = styled.div`
  margin: auto;
`;

const Sortgroup = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px auto;
  @media (min-width: 600px) {
    margin: auto;
  }
`;

const Selecttag = styled.select`
  margin: auto 0;
  width: 300px;
  font-size: 1rem;
  padding: 0.1em 0.5em 0.1em 0.5em;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
`;

const Optiontag = styled.option`
  font-size: 0.8rem;
`;

const Sortcontainer = styled.div`
  display: flex;
  width: 100%;
  background-color: #fff;
  margin: auto;
  font-family: Rubik;
  height: 75px;
  @media (min-width: 600px) {
    height: 40px;
  }
`;

const Sortcenter = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
  @media (min-width: 600px) {
    flex-direction: row;
  }
`;
const SortTitle = styled.span`
  font-weight: 500;
  font-size: 0.8rem;
  margin: 0 5px;
  @media (min-width: 600px) {
    font-weight: 700;
    font-size: 1.2rem;
  }
`;

const Radiocontent = styled.div`
  display: flex;
  margin: auto 5px;
`;

const Radiolabel = styled.label`
  display: flex;
  margin: auto;
  font-size: 0.8rem;
  @media (min-width: 600px) {
    font-size: 1.2rem;
  }
`;
const Radioinput = styled.input`
  margin: auto 5px;
`;

const Rootcontainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  ${(props) =>
    props.fixed &&
    css`
      position: fixed;
      top: 0;
      z-index: 99;
    `}
`;

const Placeholder = styled.div`
  height: 100px;
`;
export default enhance(WeDeliverBar);
