import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { clearUserInfo } from '../store/actions/addressFromAction';
import { signOut } from '../store/actions/authActions';
import { emptyCart } from '../store/actions/checkoutAction';

// Design
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'wrap',
    backgroundColor: '#0054a6',
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '10px',
    paddingBottom: '10px',
    [theme.breakpoints.up('sm')]: {
      height: '200px',
    },
  },
  aboutuscontainer: {
    display: 'flex',
    margin: '0 auto',
    boxSizing: 'border-box',
    flexDirection: 'column',
    color: '#fff',
    [theme.breakpoints.up('sm')]: {
      flexBasis: '40%',
    },
  },
  companyinfo: {
    display: 'flex',
    margin: '0 auto',
    boxSizing: 'border-box',
    flexDirection: 'column',
    color: '#fff',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      flexBasis: '30%',
    },
  },
  infodetails: {
    display: 'flex',
    margin: 'auto',
    width: '95%',
    fontSize: '0.8rem',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
      width: '100%',
      margin: '0 auto',
      marginLeft: '45px',
    },
  },
  infotitle: {
    display: 'flex',
    margin: 'auto',
    cursor: 'pointer',
    padding: '0 10px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '5px',
      marginBottom: '10px',
      padding: 0,
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  socialmedia: {
    display: 'flex',
    margin: '0 auto',
    flexDirection: 'column',
    boxSizing: 'border-box',
    color: '#fff',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      flexBasis: '30%',
    },
  },
  groupicons: {
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto',
    justifyContent: 'space-around',
    width: '25%',
    [theme.breakpoints.up('sm')]: {
      width: '35%',
      margin: '0 auto',
    },
  },
  divicons: {
    display: 'flex',
    width: '25px',
    height: '25px',
    borderRadius: '7px',
    backgroundColor: '#8f8e8f',
    [theme.breakpoints.up('sm')]: {
      width: '40px',
      height: '40px',
    },
    '&:hover': {
      backgroundColor: '#636163',
    },
  },
  socialicons: {
    color: '#fff',
    margin: 'auto',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
    },
  },
  fbname: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  instagram: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    fontSize: '1rem',
    fontWeight: 'bold',
    margin: '10px auto',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
    },
  },
  ptag: {
    display: 'flex',
    margin: 'auto',
    width: '90%',
    fontSize: '0.8rem',
    fontWeight: '300',
    textAlign: 'justify',
    [theme.breakpoints.up('sm')]: {
      margin: '0 auto',
    },
  },
  linktag: {
    display: 'flex',
    margin: 'auto',
  },
  signinout: {
    display: 'flex',
    margin: 'auto',
    marginTop: '15px',
    width: '100%',
  },
  grouplinks: {
    margin: 'auto',
    color: '#fff',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
  },
  spansigninout: {
    margin: 'auto',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const auth = useSelector((state) => state.firebase.auth);
  const [isSignedIn, setIsSignedIn] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleToggleSignInOut = (value) => {
      setIsSignedIn(value);
    };
    if (!auth.uid) {
      handleToggleSignInOut(false);
    } else {
      handleToggleSignInOut(true);
    }
  }, [auth]);

  const handleLogOut = () => {
    dispatch(emptyCart());
    dispatch(clearUserInfo());
    dispatch(signOut());
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.aboutuscontainer}>
          <h2 className={classes.title}>About Us</h2>
          <p className={classes.ptag}>Triple J Payless SuperFresh opened its doors in November 2019 at the Townhouse Center in Chalan Kanoa, offering customers a wider variety of the freshest produce on island, a first-of-its-kind Butcher Shop featuring Certified Angus Beef and a dedicated dairy and deli selection. Our Store hours are 7AM to 8AM for Senior Citizens and 8AM to 9PM for the general public everyday.</p>
        </div>
        <div className={classes.companyinfo}>
          <h2 className={classes.title}>Company Info</h2>
          <div className={classes.infodetails}>
            <Link to='/companyinfo/refunds'>
              <span className={classes.infotitle}>Refunds & Returns</span>
            </Link>
            <Link to='/companyinfo/payment'>
              <span className={classes.infotitle}>Payment Methods</span>
            </Link>
            <Link to='/companyinfo/privacy'>
              <span className={classes.infotitle}>Privacy Policy</span>
            </Link>
            <Link to='/companyinfo/terms'>
              <span className={classes.infotitle}>Terms & Conditions</span>
            </Link>
          </div>
        </div>
        <div className={classes.socialmedia}>
          <h2 className={classes.title}>Connect with Us</h2>
          <div className={classes.groupicons}>
            <div className={classes.divicons}>
              <a className={classes.linktag} href='https://www.facebook.com/paylesssuperfreshtruckloadstore' target='_blank' rel='noopener'>
                <span className={classes.socialicons}>
                  <i className='fa fa-facebook'></i>
                </span>
              </a>
            </div>
            <div className={classes.divicons}>
              <a className={classes.linktag} href='https://www.instagram.com/paylesssuperfresh/' target='_blank' rel='noopener'>
                <span className={classes.socialicons}>
                  <i className='fa fa-instagram'></i>
                </span>
              </a>
            </div>
          </div>
          {isSignedIn ? (
            <div className={classes.signinout}>
              <div className={classes.grouplinks}>
                <Link to='/'>
                  <span className={classes.spansigninout} onClick={handleLogOut}>
                    {`Log Out `}
                    <i className='fa fa-sign-out'></i>
                  </span>
                </Link>

                <Link to='/admin'>
                  <span className={classes.spansigninout}>
                    {`Admin `}
                    <i className='fa fa-lock'></i>
                  </span>
                </Link>
              </div>
            </div>
          ) : (
            <div className={classes.signinout}>
              <div className={classes.grouplinks}>
                <Link to='/signup'>
                  <span className={classes.spansigninout}>
                    {`Sign Up `}
                    <i className='fa fa-pencil-square-o'></i>
                  </span>
                </Link>
                <Link to='/signin'>
                  <span className={classes.spansigninout}>
                    {`Login In `}
                    <i className='fa fa-sign-in'></i>
                  </span>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Footer;
