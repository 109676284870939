import React from 'react';
import imgLogo from '../../images/PaylessLogo.png';

const HeaderGroup = ({
  handleSearchValue,
  searchFilter,
  handleSearchSubmit,
}) => {
  /*   let [input, setInput] = useState('');
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    setInput(e.target.value);
  }; */

  // const handleKeyPress = (e) => {
  //   console.log(e.charCode);
  //   console.log(input);
  //   if (e.charCode === 13) {
  //     dispatch(filterSearch(input));
  //     setInput('');
  //   }
  // };

  return (
    <div className='headergroup-root'>
      <div className='headergroup-img-container'>
        <img src={imgLogo} alt='logo' className='headergroup-logo' />
        <div className='headergroup-img'></div>
      </div>
      <div className='headergroup-form-container'>
        <div className='headergroup-form-center'>
          <form className='headergroup-form' onSubmit={handleSearchSubmit}>
            <input
              type='text'
              placeholder='Search...'
              name='search'
              className='headergroup-input'
              onChange={handleSearchValue}
              value={searchFilter}
            />
            <button type='submit' className='headergroup-submit-btn'>
              <i className='fa fa-search'></i>
            </button>
          </form>
        </div>
      </div>
      <div className='headergroup-msgs'>
        <h2 className='headergroup-msg-title'>We Are In This Together</h2>
        <p className='headergroup-msg-subtitle'>• Inafa'maolek • Tipiyeew •</p>
        <p className='headergroup-msg-text'>
          Introducing Saipan’s first online grocery store – shop at your
          convenience from anywhere! Let us help make life a little easier for
          you – browse our inventory, click on items for purchase, add to cart,
          checkout and a friendly customer representative will finalize
          transaction and payment details over the phone - it’s that easy!
        </p>
      </div>
    </div>
  );
};

export default HeaderGroup;
