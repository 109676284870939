import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import currencyFormat from '../../config/currencyFormat';
import axios from 'axios';

export const resetCheckoutData = () => {
  return {
    type: 'RESET',
  };
};

export const addToCart = (product) => (dispatch) => {
  dispatch({
    type: 'ADD_TO_CART',
    payload: product,
  });
  toast.info('SUCCESSFULLY ADDED TO CART');
};

export const emptyCart = () => {
  return {
    type: 'EMPTY_CART',
  };
};

export const updateCart = (newCart) => {
  return {
    type: 'UPDATE_CART',
    payload: newCart,
  };
};

export const deleteItem = (index, getState) => (dispatch) => {
  // Remove from array object product
  // console.log(getState);
  try {
    let updatedProduct = [];
    getState.splice(index, 1);
    updatedProduct = getState;
    // console.log('deleteitem action');
    // console.log(updatedProduct);

    dispatch({
      type: 'DELETE_ITEM',
      payload: updatedProduct,
    });
  } catch (error) {
    console.error(error);
  }
};

export const addServiceFee = (toggle) => {
  return {
    type: 'ADD_SERVICE_FEE',
    payload: toggle,
  };
};

export const createCheckOutCOD = (transdata) => (
  dispatch,
  getState,
  { getFirestore }
) => {
  //make async call to DB
  const firestore = getFirestore();

  let seqNoData = {};
  try {
    firestore
      .collection('seqNo')
      .doc('--count--')
      .get()
      .then((doc) => {
        if (doc.exists) {
          seqNoData = doc.data();

          let curDte = new Date();
          let date = ('0' + curDte.getDate()).slice(-2);
          let month = ('0' + (curDte.getMonth() + 1)).slice(-2);
          let hh = ('0' + curDte.getHours()).slice(-2);
          let mm = ('0' + curDte.getMinutes()).slice(-2);
          let ss = ('0' + curDte.getSeconds()).slice(-2);
          let dateString = `${month}/${date}/${curDte.getFullYear()} ${hh}:${mm}:${ss}`;

          firestore
            .collection('checkOutHeader')
            .add({
              transRef: transdata.transRef,
              Items: transdata.Items,
              Customer: transdata.Customer,
              totalAmount: transdata.totalAmount,
              createdAt: dateString,
              seqNo: seqNoData.counter,
              orderStatus: 'pending',
              paymentMethod: transdata.paymentMethod,
            })
            .then(() => {
              // Increment SeqNo
              const increment = firestore.FieldValue.increment(1);
              const seqNoRef = firestore.collection('seqNo').doc('--count--');
              seqNoRef.update({ counter: increment });
              dispatch({ type: 'CHECKOUT_COMPLETE', payload: transdata });
            })
            .catch((err) => {
              console.error(err);
              dispatch({ type: 'CHECKOUT_FAILED', payload: err });
            });
        } else {
          console.log('No such document!');
          dispatch({ type: 'CHECKOUT_FAILED', payload: 'Document not found' });
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: 'CHECKOUT_FAILED', payload: error });
      });
  } catch (error) {
    dispatch({ type: 'CHECKOUT_FAILED', payload: 'Database not available' });
  }
};

export const sendMail = (data) => (dispatch) => {
  // console.log(data);
  // dispatch({ type: 'EMAIL_SUCCESS' });
  // return;

  let serviceFee = 0;
  if (data.serviceFee !== 0) {
    serviceFee = data.serviceFee;
  }

  let templateItemDetails = `<div><table style="width: 100%;
  font-family: Arial, Helvetica, sans-serif; border-spacing: 0;">
  <thead style="border-collapse: collapse;">
    <tr style="height:50px;">
      <th style="border-bottom: 2px solid rgb(114, 114, 114); width:12%; text-align:center;">Item Code</th>
      <th style="border-bottom: 2px solid rgb(114, 114, 114); width:50%; text-align:center;">Product</th>
      <th style="border-bottom: 2px solid rgb(114, 114, 114); width:'12%';text-align:center;">Qty</th>
      <th style="border-bottom: 2px solid rgb(114, 114, 114); width:'12%';text-align:center;">Unit</th>
      <th style="border-bottom: 2px solid rgb(114, 114, 114); width:'13%';text-align:center;">Price</th>
      <th style="border-bottom: 2px solid rgb(114, 114, 114); width:'13%';text-align:center;">Total</th>
    </tr>
  </thead>`;

  let k = `<tbody id="tbody">`;
  // Loop into the Items Array to create table data
  for (let i = 0; i < data.Items.length; i++) {
    k += `<tr style="padding:5px; height:35px;">`;
    k += `<td style="text-align:left; padding: 4px; margin: 3px; border: 1px solid #ccc">${data.Items[i].ItemCode}</td>`;
    k += `<td style="text-align:left; padding: 4px; margin: 3px; border: 1px solid #ccc">${data.Items[i].ItemName}</td>`;
    k += `<td style="text-align:center;padding: 4px; margin: 3px; border: 1px solid #ccc">${data.Items[i].CartQty}</td>`;
    k += `<td style="text-align:center;padding: 4px; margin: 3px; border: 1px solid #ccc">${data.Items[i].UOM}</td>`;
    k += `<td style="text-align:right;padding: 4px; margin: 3px; border: 1px solid #ccc">$${currencyFormat(
      parseFloat(data.Items[i].SellPrice)
    )}</td>`;
    k += `<td style="text-align:right;padding: 4px; margin: 3px; border: 1px solid #ccc">$${currencyFormat(
      parseFloat(data.Items[i].TotalAmount)
    )}</td>`;
  }
  let r = `<tr>
            <td style="">&nbsp;</td>
            <td style="">&nbsp;</td>
            <td style="">&nbsp;</td>
            <td style="">&nbsp;</td>
            <td style="padding: 4px; margin: 3px; text-align:center; font-weight: bold; border: 1px solid #ccc";>Delivery Fee</td>
            <td style="padding: 4px; margin: 3px; text-align:right; font-weight: bold; border: 1px solid #ccc";>$${currencyFormat(
              parseFloat(serviceFee)
            )}</td>
          <tr>
            <td style="">&nbsp;</td>
            <td style="">&nbsp;</td>
            <td style="">&nbsp;</td>
            <td style="">&nbsp;</td>
            <td style="padding: 4px; margin: 3px; text-align:center; font-weight: bold; border: 1px solid #ccc";>Total</td>
            <td style="padding: 4px; margin: 3px; text-align:right; font-weight: bold; border: 1px solid #ccc";>$${currencyFormat(
              data.totalAmount
            )}</td>
          </tr></tbody></table></div>`;

  k += r;

  templateItemDetails += k;

  const templateid = 'template_A6irXuTq';
  const serviceID = 'everyone_net_server';
  const userID = process.env.REACT_APP_emailjsuserid;
  const templateParams = {
    message_html: templateItemDetails,
    trans_ref: data.transRef,
    customer_email: data.Customer.email,
    to_name: data.Customer.name,
    address: data.Customer.address.address,
    village: data.Customer.address.village,
    phone: data.Customer.phone,
    loyalty: data.Customer.loyalty,
    direction: data.Customer.direction,
    feedback: data.Customer.feedback,
  };

  try {
    emailjs
      .send(serviceID, templateid, templateParams, userID)
      .then(() => {
        dispatch({ type: 'EMAIL_SUCCESS' });
        // console.log(res);
        // console.log('Email Success');
      })
      .catch((error) => {
        dispatch({ type: 'EMAIL_FAILED', payload: error });
        console.error('Failed', error);
      });
  } catch (error) {
    dispatch({ type: 'EMAIL_FAILED', payload: error });
    console.log(`Catch error message ${error}`);
  }
};

export const sendNodeMailer = (data) => (dispatch) => {
  let serviceFee = 0;
  if (data.serviceFee !== 0) {
    serviceFee = data.serviceFee;
  }

  let paymentOption;

  if (data.paymentMethod === 'card') {
    paymentOption = 'Credit Card';
  } else if (data.paymentMethod === 'cod') {
    paymentOption = 'For Delivery';
  } else {
    paymentOption = 'For Pickup';
  }

  let templateItemDetails = `<div><table style="width: 100%;
  font-family: Arial, Helvetica, sans-serif; border-spacing: 0;">
  <thead style="border-collapse: collapse;">
    <tr style="height:50px;">
      <th style="border-bottom: 2px solid rgb(114, 114, 114); width:12%; text-align:center;">Item Code</th>
      <th style="border-bottom: 2px solid rgb(114, 114, 114); width:50%; text-align:center;">Product</th>
      <th style="border-bottom: 2px solid rgb(114, 114, 114); width:'12%';text-align:center;">Qty</th>
      <th style="border-bottom: 2px solid rgb(114, 114, 114); width:'12%';text-align:center;">Unit</th>
      <th style="border-bottom: 2px solid rgb(114, 114, 114); width:'13%';text-align:center;">Price</th>
      <th style="border-bottom: 2px solid rgb(114, 114, 114); width:'13%';text-align:center;">Total</th>
    </tr>
  </thead>`;

  let k = `<tbody id="tbody">`;
  // Loop into the Items Array to create table data
  for (let i = 0; i < data.Items.length; i++) {
    k += `<tr style="padding:5px; height:35px;">`;
    k += `<td style="text-align:left; padding: 4px; margin: 3px; border: 1px solid #ccc">${data.Items[i].ItemCode}</td>`;
    k += `<td style="text-align:left; padding: 4px; margin: 3px; border: 1px solid #ccc">${data.Items[i].ItemName}</td>`;
    k += `<td style="text-align:center;padding: 4px; margin: 3px; border: 1px solid #ccc">${data.Items[i].CartQty}</td>`;
    k += `<td style="text-align:center;padding: 4px; margin: 3px; border: 1px solid #ccc">${data.Items[i].UOM}</td>`;
    k += `<td style="text-align:right;padding: 4px; margin: 3px; border: 1px solid #ccc">$${currencyFormat(
      parseFloat(data.Items[i].SellPrice)
    )}</td>`;
    k += `<td style="text-align:right;padding: 4px; margin: 3px; border: 1px solid #ccc">$${currencyFormat(
      parseFloat(data.Items[i].TotalAmount)
    )}</td>`;
  }
  let r = `<tr>
            <td style="">&nbsp;</td>
            <td style="">&nbsp;</td>
            <td style="">&nbsp;</td>
            <td style="">&nbsp;</td>
            <td style="padding: 4px; margin: 3px; text-align:center; font-weight: bold; border: 1px solid #ccc";>Delivery Fee</td>
            <td style="padding: 4px; margin: 3px; text-align:right; font-weight: bold; border: 1px solid #ccc";>$${currencyFormat(
              parseFloat(serviceFee)
            )}</td>
          <tr>
            <td style="">&nbsp;</td>
            <td style="">&nbsp;</td>
            <td style="">&nbsp;</td>
            <td style="">&nbsp;</td>
            <td style="padding: 4px; margin: 3px; text-align:center; font-weight: bold; border: 1px solid #ccc";>Total</td>
            <td style="padding: 4px; margin: 3px; text-align:right; font-weight: bold; border: 1px solid #ccc";>$${currencyFormat(
              data.totalAmount
            )}</td>
          </tr></tbody></table></div>`;

  k += r;

  templateItemDetails += k;

  const subjectTemplate = `Your order# ${data.transRef} from Payless Superfresh`;

  /* dispatch({ type: 'EMAIL_SUCCESS' });
  return; */
  let body = {
    to_name: data.Customer.name,
    customer_email: data.Customer.email,
    loyalty: data.Customer.loyalty,
    address: data.Customer.address.address,
    village: data.Customer.address.village,
    phone: data.Customer.phone,
    direction: data.Customer.direction,
    message_html: templateItemDetails,
    feedback: data.Customer.feedback,
    subject: subjectTemplate,
    payment_option: paymentOption,
  };

  try {
    axios
      .post('/sendEmail', body)
      .then((res) => {
        // console.log(res);
        dispatch({ type: 'EMAIL_SUCCESS' });
      })
      .catch((error) => {
        dispatch({ type: 'EMAIL_FAILED', payload: error.code });
        console.error(error);
      });
  } catch (error) {
    dispatch({ type: 'EMAIL_FAILED', payload: error });
    console.log('Error', error.code);
  }
};
