export const ADD_USER = 'ADD_USER';
export const ADD_CARD = 'ADD_CARD';

export const addUser = (userInfo) => {
  return {
    type: ADD_USER,
    payload: userInfo,
  };
};

export const clearUserInfo = () => {
  return (dispatch) => {
    dispatch({ type: 'CLEAR_USER' });
  };
};

export const loadProfile = (profile) => (dispatch) => {
  dispatch({ type: 'LOAD_PROFILE', payload: profile });
};
