import React from 'react';

import defaultImg from '../../images/no-product-image-available.png';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-start',
    width: '98%',
    margin: '20px auto',
    borderBottom: '1px solid #ccc',
    padding: '10px 0',
    // border: '1px solid #dbdbdb',
    // borderRadius: '5px',
  },
  imgcontainer: {
    display: 'flex',
    height: '120px',
  },
  image: {
    height: '80%',
    margin: 'auto',
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '250px',
  },
  title: {
    color: 'green',
    fontSize: '.8rem',
    fontWeight: 'Bold',
  },
  sku: {
    color: '#aeb0ad',
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  label: {
    color: '#1b262c',
    fontSize: '1rem',
  },
  onhand: {
    margin: 'auto',
    color: '#1b262c',
    fontSize: '1rem',
    fontWeight: '900',
  },
  uom: {
    margin: 'auto',
    color: '#1b262c',
    fontSize: '1rem',
    fontWeight: '900',
  },
  price: {
    margin: 'auto',
    color: '#1b262c',
    fontSize: '1rem',
    fontWeight: '900',
  },
  category: {
    margin: 'auto',
    color: '#1b262c',
    fontSize: '1rem',
    fontWeight: '900',
  },
  deal: {
    margin: 'auto',
    color: '#1b262c',
    fontSize: '1rem',
    fontWeight: '900',
  },
}));

const ItemList = ({ product, handleSelectItem }) => {
  const classes = useStyles();

  // useEffect(() => {
  //   window.addEventListener('click', () => {
  //     console.log(`Select ${product.id}`);
  //   });
  // }, []);

  return (
    <div className={classes.root}>
      <span onClick={() => handleSelectItem(product.id)}>x</span>
      <div className={classes.imgcontainer}>
        <img
          id='itemImg'
          src={product.Image || defaultImg}
          alt='itemImg'
          className={classes.image}
        />
      </div>
      <div className={classes.description}>
        <p className={classes.title}>{product.ItemName}</p>
        <p className={classes.sku}>
          <span>#{product.ItemCode}</span>
        </p>
      </div>
      <div className={classes.onhand}>
        <p>{product.Onhand}</p>
      </div>
      <div className={classes.uom}>
        <p>{product.UOM}</p>
      </div>
      <div className={classes.price}>
        <p>${product.SellPrice}</p>
      </div>
      <div className={classes.category}>
        <p>{product.Category}</p>
      </div>
      <div className={classes.deal}>
        <p>{product.HotDeals.toString()}</p>
      </div>
    </div>
  );
};

export default ItemList;
