import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import InventoryForm from '../components/admin/InventoryForm';
import CustomerTransaction from '../components/admin/CustomerTransaction';
import HeaderGroup from '../components/header-group/HeaderGroupStandard';

import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Paper, Box, Typography, AppBar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '90%',
    marginTop: '15px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  Appbar: {
    backgroundColor: '#0054a6',
    display: 'flex',
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

const Admin = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const auth = useSelector((state) => state.firebase.auth);
  const isAdmin = useSelector((state) => state.firebase.profile);
  if (!auth.uid && !isAdmin.admin) return <Redirect to='/signin' />;

  const handTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // let handleChangeInventoryForm = (input) => (e) => {
  //   console.log(input);
  //   console.log(e.target.value);
  // };

  return (
    <>
      <HeaderGroup />
      <React.Fragment>
        <Paper className={classes.root}>
          <AppBar position='sticky' className={classes.Appbar}>
            <Tabs
              variant='fullWidth'
              value={value}
              onChange={handTabChange}
              centered
            >
              <Tab label='Customer Order Detail' />
              <Tab label='Inventory List' />
              <Tab label='System Configuration' />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <CustomerTransaction />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <InventoryForm />
          </TabPanel>
          <TabPanel value={value} index={2}>
            From System Configuration Tab
          </TabPanel>
        </Paper>
      </React.Fragment>
    </>
  );
};

export default Admin;
