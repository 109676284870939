import axios from 'axios';
import validator from 'validator';

export const searchTransaction = (refNo) => (dispatch) => {
  dispatch({ type: 'PROCESSING' });

  if (!validator.isEmpty(refNo)) {
    try {
      axios
        .get(`/transaction/${refNo.trim()}`)
        .then((res) => {
          // console.log(res);
          dispatch({ type: 'DATAFOUND', payload: res.data });
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: 'ERROR' });
        });
    } catch (error) {
      console.error(`From try catch error: ${error}`);
      dispatch({ type: 'ERROR' });
    }
  }
};

export const cancelUpdate = () => {
  return { type: 'CANCEL_UPDATE' };
};

export const deleteItem = (index, getState) => (dispatch) => {
  try {
    let updatedOrder = [];
    getState.splice(index, 1);
    updatedOrder = getState;
    console.log(updatedOrder);

    dispatch({
      type: 'DELETE_ITEM',
      payload: updatedOrder,
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateOrder = (orderChanges) => {
  return { type: 'UPDATE_CHANGES', payload: orderChanges };
};

export const postUpdateOrder = (updatedOrder) => (dispatch) => {
  let bodyData = {
    id: updatedOrder.id,
    orderStatus: updatedOrder.orderStatus,
    createdAt: updatedOrder.createdAt,
    totalAmount: parseFloat(updatedOrder.totalAmount),
    transRef: updatedOrder.transRef,
    seqNo: updatedOrder.seqNo,
    Customer: {
      customerID: updatedOrder.custID,
      direction: updatedOrder.direction,
      email: updatedOrder.email,
      loyalty: updatedOrder.loyalty,
      name: updatedOrder.custName,
      phone: updatedOrder.phone,
      address: {
        address: updatedOrder.custAddress,
        village: updatedOrder.custVillage,
      },
    },
    Items: updatedOrder.Items,
  };
  /* console.log(bodyData); */
  /* return dispatch({ type: 'SUCCESS_UPDATE' }); */
  try {
    axios
      .post('/transaction/update', bodyData)
      .then((res) => {
        /* console.log(res.data); */
        dispatch({ type: 'SUCCESS_UPDATE' });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: 'ERROR' });
      });
  } catch (error) {
    console.log(error);
    dispatch({ type: 'ERROR' });
  }
};
