import { ADD_USER } from '../actions/addressFromAction';

const initState = {
  userinfo: {
    cardName: '',
    email: '',
    phone: '',
    address: '',
    village: '',
    loyalty: '',
    direction: '',
    feedback: '',
  },
};

const userForm = (state = initState, { type, payload }) => {
  switch (type) {
    case ADD_USER:
      return {
        ...state,
        userinfo: payload,
      };
    case 'LOAD_PROFILE':
      // console.log(payload);
      let profileData = {
        cardName: `${payload.firstName} ${payload.lastName}`,
        email: payload.email,
        phone: payload.phone,
        address: payload.address,
        village: payload.village,
        loyalty: payload.loyalty,
        direction: payload.direction,
        feedback: '',
      };
      return {
        ...state,
        userinfo: profileData,
      };
    case 'CLEAR_USER':
      state = initState;
      return state;
    default:
      return state;
  }
};

export default userForm;
