import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import ClipLoader from 'react-spinners/ClipLoader';

import {
  InventoryForm as ItemForm,
  CancelInventory,
  AddInventory,
} from '../../store/actions/inventoryAction';

import { connect, useSelector, useDispatch } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

import ItemList from './ItemList';

import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField, Paper, MenuItem } from '@material-ui/core';

const uomList = [
  { value: 'BG', label: 'BAG' },
  { value: 'BX', label: 'BOX' },
  { value: 'CS', label: 'CASE' },
  { value: 'DR', label: 'DRUM' },
  { value: 'DZ', label: 'DOZEN' },
  { value: 'EA', label: 'EACH' },
  { value: 'FT', label: 'FEET' },
  { value: 'GAL', label: 'GALLON' },
  { value: 'LBS', label: 'POUNDS' },
  { value: 'MT', label: 'METER' },
  { value: 'NONE', label: 'NONE' },
  { value: 'PK', label: 'PACK' },
  { value: 'PK10', label: 'PACK OF 10' },
  { value: 'PK12', label: 'PACK OF 12' },
  { value: 'PK15', label: 'PACK OF 15' },
  { value: 'PK20', label: 'PACK OF 20' },
  { value: 'PK4', label: 'PACK OF 4' },
  { value: 'PK6', label: 'PACK OF 6' },
  { value: 'PK8', label: 'PACK OF 8' },
  { value: 'PL', label: 'PAIL' },
  { value: 'PLT', label: 'PALLET' },
  { value: 'PR', label: 'PAIR' },
  { value: 'QRT', label: 'QUART' },
  { value: 'SET', label: 'SET' },
  { value: 'SHPR', label: 'SHIPPER' },
  { value: 'SLV', label: 'SLEEVE' },
  { value: 'UNIT', label: 'UNIT' },
  { value: 'YD', label: 'YARD' },
];

const enhance = compose(
  firestoreConnect((props) => {
    return [{ collection: 'inventory', orderBy: 'ItemName' }];
  }),
  connect((state) => ({
    inventory: state.firestore.ordered.inventory,
  }))
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '10px',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20ch',
  },
  itemcode: {
    flex: '1',
  },
  itemname: {
    flex: '6',
    marginLeft: '10px',
  },
  line1: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    margin: '10px',
  },
  line2: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    width: '100%',
    padding: '20px 0',
    margin: '10px auto',
    height: '150px',
    [theme.breakpoints.up('md')]: {
      height: 'auto',
    },
  },
  uploadcontainer: {
    display: 'flex',
    width: '100%',
    marginLeft: '5px',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  btn: {
    fontSize: '1rem',
    fontWeight: '600',
    color: '#3b3d3b',
    width: '100px',
    height: '2rem',
    cursor: 'pointer',
    margin: '5px',
    backgroundColor: '#cccecc',
    backgroundImage: 'linear-gradient(180deg, #dadbd9, #b8bab7)',
    // textShadow: '0 1px 1px hsla(0, 0%, 100%, 0.75)',
    borderRadius: '5px',
    '&:hover': {
      background: '#323232',
      color: '#fff',
    },
  },
  titleheader: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      width: '98%',
      justifyContent: 'start',
      margin: 'auto',
      marginTop: '20px',
      borderBottom: '1px solid #000',
      fontSize: '0.8rem',
    },
    display: 'none',
  },
  imagepreview: {
    width: '200px',
    minHeight: '150px',
    border: '2px solid #8e8e8f',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#8e8e8f',
  },
}));

Modal.setAppElement('#root');

const InventoryForm = ({ inventory }) => {
  const classes = useStyles();
  const inventoryState = useSelector((state) => state.inventory);
  const errormsg = useSelector((state) => state.inventory.errormsg);
  const processing = useSelector((state) => state.inventory.loading);
  const resultmsg = useSelector((state) => state.inventory.resultmsg);
  const status = useSelector((state) => state.inventory.status);
  const inventoryData = useSelector(
    (state) => state.firestore.ordered.inventory
  );

  const [isDisabled, setIsDisabled] = useState(true);
  const [fileImage, setFileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isDefaultImg, setIsDefaultImg] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isProcessing, setProcessingTo] = useState(processing);
  const [modalMsg, setModalMsg] = useState('');
  const [actionType, setActionType] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (errormsg !== '') {
      setProcessingTo(false);
      setModalIsOpen(true);
      setModalMsg(errormsg);
      setIsDefaultImg(true);
      return;
    }

    if (resultmsg !== '') {
      setProcessingTo(false);
      setModalIsOpen(true);
      setModalMsg(resultmsg);
      setIsDefaultImg(false);
      return;
    }

    if (status !== '') {
      setIsDefaultImg(true);
      const inputImg = document.getElementById('imageInputID');
      inputImg.value = null;
    }
  }, [errormsg, resultmsg, status]);

  const handleChange = (prop) => (ev) => {
    inventoryState[prop] = ev.target.value;
    dispatch(ItemForm(inventoryState));
  };

  const handleSubmitInventory = (ev) => {
    ev.preventDefault();

    setProcessingTo(true);

    /* Validation */

    if (
      inventoryState.ItemCode === '' ||
      inventoryState.ItemName === '' ||
      inventoryState.Onhand <= 0 ||
      inventoryState.SellPrice <= 0
    ) {
      setModalIsOpen(true);
      setModalMsg('Invalid information cannot be process');
      setProcessingTo(false);
      return;
    }

    if (actionType === 'add') {
      dispatch(AddInventory(inventoryState, fileImage));
    }

    if (errormsg !== '') {
      setProcessingTo(false);
      setModalIsOpen(true);
      setModalMsg(errormsg);
      return;
    }

    if (resultmsg !== '') {
      setProcessingTo(false);
      setModalIsOpen(true);
      setModalMsg(resultmsg);
      return;
    }
  };

  const handleClick = (ev) => {
    let type = ev.target.id;
    setActionType(type);
    if (type !== 'save' && type !== 'cancel') {
      setIsDisabled(false);
    } else {
      if (type === 'cancel') {
        const initInfo = {
          ItemCode: '',
          ItemName: '',
          Onhand: 0,
          SellPrice: 0,
          UOM: 'EA',
          HotDeals: false,
          Features: false,
          Category: 'GROCERY',
        };
        setIsDefaultImg(true);
        dispatch(CancelInventory(initInfo));
      }
      setIsDisabled(true);
    }
  };

  const fileSelectedHandler = (ev) => {
    const newFile = ev.target.files[0];
    if (newFile) {
      setFileImage(newFile);
      const reader = new FileReader();

      reader.addEventListener('load', (result) => {
        setIsDefaultImg(false);
        setPreviewImage(result.target.result);
      });
      reader.readAsDataURL(newFile);
    }
  };

  const handleSelectItem = (id) => {
    const data = inventoryData.find((item) => item.id === id);
    let foundData = {
      ItemCode: data.ItemCode,
      ItemName: data.ItemName,
      Onhand: data.Onhand,
      SellPrice: data.SellPrice,
      UOM: data.UOM.trim(),
      Category: data.Category.trim(),
    };
    if (data.Image !== '') {
      setIsDefaultImg(false);
      setPreviewImage(data.Image);
    } else {
      setIsDefaultImg(true);
    }
    dispatch(ItemForm(foundData));
  };

  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      border: '1px solid #000',
      background: '#143454',
    },
  };

  let subtitle = '';
  let content = '';
  let button = '';
  const afterOpenModal = () => {
    subtitle.style.color = '#fff';
    subtitle.style.height = '20px';
    content.style.marginTop = '20px';
    content.style.marginBottom = '20px';
    content.style.color = '#fff';
    button.style.height = '40px';
    button.style.width = '100px';
    button.style.borderRadius = '10px';
    button.style.border = '0';
    button.style.cursor = 'pointer';
    button.style.background = '#0054a6';
    button.style.color = '#fff';
  };

  const override = `
    display: block;
    margin: 0 auto;        
  `;

  return (
    <React.Fragment>
      <div className='.loader-center'>
        <ClipLoader
          size={100}
          color={'#0054a6'}
          loading={isProcessing}
          css={override}
        />
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        onAfterOpen={afterOpenModal}
        style={modalStyle}
        contentLabel='Pop Up Messages'
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Information Message</h2>
        <p ref={(_content) => (content = _content)}>{modalMsg}</p>
        <div>
          <button
            ref={(_button) => (button = _button)}
            onClick={() => setModalIsOpen(false)}
          >
            Close
          </button>
        </div>
      </Modal>

      <Paper>
        <form onSubmit={handleSubmitInventory}>
          <div className={classes.root}>
            <div className={classes.line1}>
              <FormControl disabled={isDisabled}>
                <TextField
                  className={classes.itemcode}
                  required
                  id='ItemCode'
                  label='Item Code'
                  variant='outlined'
                  type='text'
                  fullWidth
                  size='small'
                  value={inventoryState.ItemCode}
                  disabled={isDisabled}
                  onChange={handleChange('ItemCode')}
                />
              </FormControl>

              <FormControl className={classes.itemname}>
                <TextField
                  required
                  id='ItemName'
                  label='Item Name'
                  variant='outlined'
                  type='text'
                  size='small'
                  fullWidth
                  value={inventoryState.ItemName}
                  disabled={isDisabled}
                  onChange={handleChange('ItemName')}
                />
              </FormControl>
            </div>

            <div className={classes.line2}>
              <FormControl>
                <TextField
                  required
                  id='Onhand'
                  label='Onhand'
                  variant='outlined'
                  type='number'
                  size='small'
                  className={classes.textField}
                  value={inventoryState.Onhand}
                  disabled={isDisabled}
                  InputProps={{ inputProps: { min: 1 } }}
                  onChange={handleChange('Onhand')}
                />
              </FormControl>

              <FormControl>
                <TextField
                  required
                  id='UOM'
                  label='UOM'
                  variant='outlined'
                  size='small'
                  select
                  className={classes.textField}
                  value={inventoryState.UOM}
                  disabled={isDisabled}
                  onChange={handleChange('UOM')}
                >
                  {uomList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>

              <FormControl>
                <TextField
                  required
                  id='SellPrice'
                  label='Sell Price'
                  variant='outlined'
                  type='number'
                  size='small'
                  InputProps={{ inputProps: { min: 0.01 } }}
                  className={classes.textField}
                  value={inventoryState.SellPrice}
                  disabled={isDisabled}
                  onChange={handleChange('SellPrice')}
                />
              </FormControl>

              <FormControl>
                <TextField
                  required
                  id='Category'
                  label='Category'
                  variant='outlined'
                  size='small'
                  className={classes.textField}
                  value={inventoryState.Category}
                  disabled={isDisabled}
                  onChange={handleChange('Category')}
                />
              </FormControl>
            </div>
            <div className={classes.uploadcontainer}>
              <div className={classes.imagepreview}>
                <img
                  src={previewImage}
                  alt='imageFile'
                  style={{
                    width: '100px',
                    display: isDefaultImg ? 'none' : 'block',
                  }}
                />
                <span style={{ display: isDefaultImg ? 'block' : 'none' }}>
                  Image Preview
                </span>
              </div>
              <label style={{ margin: '0 15px', width: '150px' }}>
                Upload Photo
              </label>
              <input
                id='imageInputID'
                type='file'
                onChange={fileSelectedHandler}
                disabled={isDisabled}
              />
              <span style={{ width: '60%' }}></span>
            </div>
          </div>
        </form>

        <div className={classes.buttons}>
          <button id='add' className={classes.btn} onClick={handleClick}>
            Add
          </button>
          <button id='edit' className={classes.btn} onClick={handleClick}>
            Edit
          </button>
          <button id='delete' className={classes.btn} onClick={handleClick}>
            Delete
          </button>
          <button
            id='cancel'
            className={classes.btn}
            onClick={handleClick}
            disabled={isDisabled}
          >
            Cancel
          </button>
          <button
            id='save'
            className={classes.btn}
            onClick={handleSubmitInventory}
            disabled={isDisabled}
          >
            Save
          </button>
        </div>
        <div className={classes.titleheader}>
          {/* <label style={{ width: '100px' }}>Image</label> */}
          <label style={{ width: '330px', marginLeft: '140px' }}>
            Description
          </label>
          <label style={{ width: '130px' }}>Onhand</label>
          <label style={{ width: '130px' }}>UoM</label>
          <label style={{ width: '140px' }}>Price</label>
          <label style={{ width: '150px' }}>Category</label>
          <label style={{ width: '100px' }}>With Special</label>
        </div>
        <div>
          {inventory &&
            inventory.map((product) => {
              return (
                <ItemList
                  key={product.id}
                  data-index={product.id}
                  product={product}
                  handleSelectItem={handleSelectItem}
                />
              );
            })}
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default enhance(InventoryForm);
