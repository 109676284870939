import React, { useEffect, useState } from 'react';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.document.addEventListener('scroll', () => {
      toggleVisibility();
    });

    return () => {
      window.removeEventListener('scroll', toggleVisibility());
    };
  }, [isVisible]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <div className='scroll-to-top'>
      {isVisible && (
        <div className='scroll-arrow-container'>
          <i
            className='fa fa-arrow-circle-o-up scroll-icon'
            onClick={scrollToTop}
          ></i>
        </div>
      )}
    </div>
  );
};

export default ScrollToTop;
