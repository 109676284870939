import React, { useState } from 'react';

import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { signUp } from '../../store/actions/authActions';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    height: 'auto',
    // marginTop: '10%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  textField: {
    width: '100%',
  },
  privacymsg: {
    paddingLeft: '15px',
  },
}));

const SignUp = () => {
  const auth = useSelector((state) => state.firebase.auth);
  /* const userInfo = useSelector((state) => {
    state.addressForm.userInfo;
  }); */
  const dispatch = useDispatch();
  const classes = useStyles();

  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    village: '',
    direction: '',
    password: '',
    loyalty: '',
    showPassword: false,
  });

  if (auth.uid) return <Redirect to='/' />;

  const handleSignUpSubmit = (ev) => {
    ev.preventDefault();
    // console.log('signup submit was trigger');
    const newUser = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      address: values.address,
      village: values.village,
      direction: values.direction,
      loyalty: values.loyalty,
      password: values.password,
    };
    dispatch(signUp(newUser));
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <main className={classes.layout}>
      <Paper className={classes.paper}>
        <form onSubmit={handleSignUpSubmit}>
          <Typography variant='h6' gutterBottom>
            Sign Up
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textField}>
                <InputLabel htmlFor='First Name'>First Name</InputLabel>
                <Input
                  required
                  type='text'
                  id='firstName'
                  label='First Name'
                  placeholder='John'
                  value={values.firstName}
                  onChange={handleChange('firstName')}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textField}>
                <InputLabel htmlFor='Last Name'>Last Name</InputLabel>
                <Input
                  required
                  type='text'
                  id='lastName'
                  label='Last Name'
                  placeholder='Smith'
                  value={values.lastName}
                  onChange={handleChange('lastName')}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textField}>
                <InputLabel htmlFor='Email'>Email</InputLabel>
                <Input
                  required
                  type='email'
                  id='email'
                  label='Email'
                  placeholder='john.smith@example.com'
                  value={values.email}
                  onChange={handleChange('email')}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textField}>
                <InputLabel htmlFor='Phone'>Phone</InputLabel>
                <Input
                  required
                  type='phone'
                  id='phone'
                  label='Phone'
                  placeholder='(999)555-3333'
                  value={values.phone}
                  onChange={handleChange('phone')}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textField}>
                <InputLabel htmlFor='Address'>Address</InputLabel>
                <Input
                  required
                  type='address'
                  id='address'
                  label='Address'
                  placeholder='Beverly St. Suite 102'
                  value={values.address}
                  onChange={handleChange('address')}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textField}>
                <InputLabel htmlFor='Village'>Village</InputLabel>
                <Input
                  required
                  type='village'
                  id='village'
                  label='Village'
                  placeholder='Chalan Kanoa'
                  value={values.village}
                  onChange={handleChange('village')}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.textField}>
                <InputLabel htmlFor='Loyalty'>Loyalty Card</InputLabel>
                <Input
                  type='loyalty'
                  id='loyalty'
                  label='Loyalty Card'
                  placeholder='TJ2017100401'
                  value={values.loyalty}
                  onChange={handleChange('loyalty')}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.textField}>
                <InputLabel htmlFor='Direction'>Direction</InputLabel>
                <Input
                  type='direction'
                  id='direction'
                  label='Direction'
                  multiline
                  placeholder='Near the hospital'
                  value={values.direction}
                  onChange={handleChange('direction')}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.textField}>
                <InputLabel>Password</InputLabel>
                <Input
                  required
                  id='password'
                  label='Password'
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  fullWidth
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button type='submit' variant='contained' color='primary'>
                Register
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.privacymsg}>
              <Typography variant='caption'>
                Your personal data will be used to support your experience
                throughout this website, to manage access to your account, and
                for other purposes described in our privacy policy.
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </main>
  );
};

export default SignUp;
