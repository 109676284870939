const initInventory = {
  ItemCode: '',
  ItemName: '',
  Onhand: 0,
  SellPrice: 0,
  UOM: 'EA',
  HotDeals: false,
  Features: false,
  Category: '',
  loading: false,
  errormsg: '',
  resultmsg: '',
  status: '',
};

const initState = {
  ItemCode: '',
  ItemName: '',
  Onhand: 0,
  SellPrice: 0,
  UOM: 'EA',
  HotDeals: false,
  Features: false,
  Category: '',
  loading: false,
  errormsg: '',
  resultmsg: '',
  status: '',
};

const inventoryReducer = (state = initInventory, { type, payload }) => {
  switch (type) {
    case 'INPUT_ITEM':
      // console.log(payload);
      return {
        ...state,
        ItemCode: payload.ItemCode.toUpperCase(),
        ItemName: payload.ItemName.toUpperCase(),
        Onhand: parseFloat(payload.Onhand),
        SellPrice: parseFloat(payload.SellPrice),
        UOM: payload.UOM,
        Category: payload.Category,
      };
    case 'CANCEL_ITEM':
      // console.log('Trigger Cancel Reducer');
      state = initState;
      return state;
    case 'UPLOAD_SUCCESS':
      // console.log('Upload Seccess');
      state = initState;
      return {
        ...state,
        resultmsg: payload,
        loading: false,
        status: 'complete',
      };
    case 'UPLOADING':
      // console.log('Uploading');
      return {
        ...state,
        loading: true,
        errormsg: '',
        resultmsg: '',
        status: '',
      };
    case 'ERROR':
      // console.log('Error msg');
      // console.log(payload);
      return {
        ...state,
        errormsg: payload,
        loading: false,
        status: 'complete',
      };
    default:
      return state;
  }
};

export default inventoryReducer;
