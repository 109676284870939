import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addToCart } from '../store/actions/checkoutAction';
import defaultImg from '../images/no-product-image-available.png';

const Item = ({ product }) => {
  const [state, setState] = useState({
    counter: 1,
    product: [],
  });
  const [isZero, setisZero] = useState(false);

  let stringPrice = product.SellPrice.toString();
  let splitPrice = stringPrice.split('.');
  let dollarValue = splitPrice[0];
  let centsValue = splitPrice[1];

  useEffect(() => {
    // console.log(product.Onhand);
    if (product.Onhand <= 0) {
      setisZero(true);
    }
  }, [product.Onhand]);

  // console.log(product);
  const dispatch = useDispatch();

  const handleInput = (e) => {
    let value = e.target.value;
    if (value >= 0) {
      setState({ ...state, counter: e.target.value });
    }
  };

  const handleAddToCart = () => {
    // let itemImg = product.Image === null ? defaultImg : product.Image;
    // console.log(itemImg);
    let ttlValue = state.counter * product.SellPrice;
    dispatch(
      addToCart({
        id: product.id,
        Image: product.Image,
        ItemCode: product.ItemCode,
        ItemName: product.ItemName,
        SellPrice: product.SellPrice,
        UOM: product.UOM,
        CartQty: parseInt(state.counter),
        Onhand: product.Onhand,
        TotalAmount: parseFloat(ttlValue),
      })
    );
  };

  const HotDeals = () => {
    return (
      <div className='on-sale'>
        <h5>Hot Deals!</h5>
      </div>
    );
  };

  return (
    <>
      <article className='item-box-group'>
        <div className='item-details-container'>
          <div className='img-container'>
            <img
              id='itemImg'
              className={isZero ? '' : 'itemImghover'}
              src={product.Image || defaultImg}
              alt='itemImg'
            />
          </div>
          {product.HotDeals ? <HotDeals /> : null}
          <div className='pricing'>
            <div className='pricing-inner'>
              {/* <span className='dollarSign'>$</span> */}
              <span className='dollarValue'>{`$${dollarValue}`}</span>
              <span className='centsValue'>{centsValue}</span>
              <span className='each'>{`/ ${product.UOM}`}</span>
            </div>
          </div>
          <div className='product-detail'>
            <p className='product-detail-desc'>{product.ItemName}</p>
            <p className='sub-text'>
              <span>#{product.ItemCode}</span>
            </p>
          </div>
          <div className='cartAndQuantity'>
            <input
              id='input_Qty'
              type='number'
              className='add_qty'
              min='1'
              max='99'
              disabled={isZero}
              value={state.counter}
              onChange={handleInput}
            />
            <button
              type='submit'
              className={isZero ? 'btn-cart out-of-stock' : 'btn-cart'}
              disabled={isZero}
              value={isZero ? 'Out of stock' : 'Add to cart'}
              onClick={handleAddToCart}
            >
              {isZero ? 'Out of stock' : 'Add to cart'}
            </button>
          </div>
        </div>
      </article>
    </>
  );
};

export default Item;
