import React from 'react';

import Header from '../components/header-group/HeaderGroupStandard';
import SignIn from '../components/auth/SignIn';

const SignUpPage = () => {
  return (
    <div>
      <Header />
      <SignIn />
    </div>
  );
};

export default SignUpPage;
