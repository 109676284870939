const initState = {
  id: '',
  seqNo: '',
  orderStatus: '',
  createdAt: '',
  totalAmount: 0,
  transRef: '',
  custID: '',
  custName: '',
  phone: '',
  email: '',
  loyalty: '',
  custAddress: '',
  custVillage: '',
  direction: '',
  Items: [],
  errormsg: '',
  updatemsg: '',
  itemupdated: false,
};

const transactionReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case 'PROCESSING':
      state = initState;
      return state;
    case 'DATAFOUND':
      // console.log(payload);
      let fetchData = payload[0];
      return {
        ...state,
        id: fetchData.id,
        seqNo: fetchData.seqNo,
        orderStatus: fetchData.orderStatus,
        createdAt: fetchData.createdAt,
        totalAmount: parseFloat(fetchData.totalAmount),
        transRef: fetchData.transRef,
        custID: fetchData.Customer.customerID,
        custName: fetchData.Customer.name,
        phone: fetchData.Customer.phone,
        email: fetchData.Customer.email,
        loyalty: fetchData.Customer.loyalty,
        custAddress: fetchData.Customer.address.address,
        custVillage: fetchData.Customer.address.village,
        direction: fetchData.Customer.direction,
        Items: fetchData.Items,
      };
    case 'ERROR':
      state = initState;
      return {
        ...state,
        errormsg: 'No Record Found',
      };
    case 'CANCEL_UPDATE':
      state = initState;
      return state;
    case 'UPDATE_CHANGES':
      let cartUpdateTotalAmount = 0;
      payload.forEach((item) => {
        cartUpdateTotalAmount = cartUpdateTotalAmount + item.TotalAmount;
      });
      return {
        ...state,
        Items: payload,
        totalAmount: parseFloat(cartUpdateTotalAmount),
        itemupdated: true,
      };
    case 'DELETE_ITEM':
      let cartDeleteTotalAmount = 0;
      payload.forEach((item) => {
        cartDeleteTotalAmount = cartDeleteTotalAmount + item.TotalAmount;
      });
      return {
        ...state,
        Items: payload,
        totalAmount: parseFloat(cartDeleteTotalAmount),
        itemupdated: true,
      };
    case 'SUCCESS_UPDATE':
      state = initState;
      return {
        ...state,
        updatemsg: 'Successfully Updated Customer Order',
      };
    default:
      return state;
  }
};

export default transactionReducer;
