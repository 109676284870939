import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import currencyFormat from '../../config/currencyFormat';
import Modal from 'react-modal';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';

import {
  searchTransaction,
  cancelUpdate,
  updateOrder,
  postUpdateOrder,
  deleteItem,
} from '../../store/actions/fetchTransaction';

import OrderList from './OrderList';

Modal.setAppElement('#root');

const CustomerTransaction = () => {
  const transData = useSelector((state) => state.transaction);
  const errorMsg = useSelector((state) => state.transaction.errormsg);
  const updateMsg = useSelector((state) => state.transaction.updatemsg);
  const itemUpdated = useSelector((state) => state.transaction.itemupdated);
  const cartItems = transData.Items;
  const [searchValue, setSearchValue] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  let cartDetail = [];

  const dispatch = useDispatch();

  useEffect(() => {
    if (errorMsg !== '') {
      setModalIsOpen(true);
      setModalMsg(errorMsg);
      setIsProcessing(false);
    }

    if (updateMsg !== '') {
      setModalIsOpen(true);
      setModalMsg(updateMsg);
      setIsProcessing(false);
    }

    if (itemUpdated) {
      setModalIsOpen(true);
      setModalMsg('Ordered items has been change!');
      setIsProcessing(false);
    }

    let recordID = transData.id;
    if (recordID !== '') {
      setIsProcessing(false);
    }
  }, [errorMsg, itemUpdated, updateMsg, transData]);

  const handleCancel = () => {
    setSearchValue('');
    if (transData.id !== '') {
      dispatch(cancelUpdate());
    } else {
      setIsProcessing(false);
      setModalMsg('No Customer Order to Update');
      setModalIsOpen(true);
    }
  };

  const handleChange = (ev) => {
    // console.log(ev.target.value);
    setSearchValue(ev.target.value);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    setIsProcessing(true);
    dispatch(searchTransaction(searchValue));
  };

  const handleChangeCart = (info) => {
    const foundIndex = cartDetail.findIndex((e) => e.index === info.index);

    if (foundIndex === -1) {
      cartDetail.push(info);
    } else {
      cartDetail[foundIndex] = info;
    }
  };

  const handleUpdateCart = () => {
    setIsProcessing(true);

    if (cartDetail.length !== 0) {
      cartDetail.forEach((item, index) => {
        cartItems[item.index].CartQty = item.newCartQty;
        let subtotal = item.newCartQty * cartItems[item.index].SellPrice;
        cartItems[item.index].TotalAmount = parseFloat(subtotal);
      });
      dispatch(updateOrder(cartItems));
    } else {
      setIsProcessing(false);
      setModalMsg('No changes has be created');
      setModalIsOpen(true);
    }
  };

  const handlePostUpdate = () => {
    setIsProcessing(true);

    if (itemUpdated) {
      dispatch(postUpdateOrder(transData));
      setSearchValue('');
    } else {
      setIsProcessing(false);
      setModalMsg('No Customer Order to Update');
      setModalIsOpen(true);
    }
  };

  const handleDeleteItem = (index) => {
    dispatch(deleteItem(index, cartItems));
  };

  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      border: '1px solid #000',
      background: '#143454',
      zIndex: '99',
    },
  };

  const modalLoading = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      zIndex: '99',
    },
  };

  let subtitle = '';
  let content = '';
  let button = '';
  const afterOpenModal = () => {
    subtitle.style.color = '#fff';
    subtitle.style.height = '20px';
    content.style.marginTop = '20px';
    content.style.marginBottom = '20px';
    content.style.color = '#fff';
    content.style.fontSize = '16px';
    button.style.height = '40px';
    button.style.width = '100px';
    button.style.borderRadius = '10px';
    button.style.border = '0';
    button.style.cursor = 'pointer';
    button.style.background = '#0054a6';
    button.style.color = '#fff';
  };

  const override = `
    display: block;
    margin: 0 auto;        
  `;

  return (
    <>
      <Modal
        isOpen={isProcessing}
        onRequestClose={() => setModalIsOpen(isProcessing)}
        style={modalLoading}
      >
        <ClipLoader
          size={100}
          color={'#0054a6'}
          loading={isProcessing}
          css={override}
        />
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        onAfterOpen={afterOpenModal}
        style={modalStyle}
        contentLabel='Pop Up Messages'
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Information Message</h2>
        <p ref={(_content) => (content = _content)}>{modalMsg}</p>
        <div>
          <button
            ref={(_button) => (button = _button)}
            onClick={() => setModalIsOpen(false)}
          >
            Return
          </button>
        </div>
      </Modal>
      <Wrapper>
        <WrapContainer>
          <Wrapinput>
            <Spantag>Transaction Reference No:</Spantag>
            <SearchInput
              type='text'
              placeholder='49385349...'
              onChange={handleChange}
              value={searchValue}
            />
            <ButtonSubmit type='button' onClick={handleSubmit}>
              <i className='fa fa-search'></i>
            </ButtonSubmit>
          </Wrapinput>
          <CustomerContainer>
            <Wrapinput>
              <Spantag>Order Status</Spantag>
              <Spaninfo>{transData.orderStatus.toUpperCase()}</Spaninfo>
            </Wrapinput>
            <Wrapinput>
              <Spantag>Customer Name</Spantag>
              <Spaninfo>{transData.custName}</Spaninfo>
            </Wrapinput>
            <Wrapinput>
              <Spantag>Loyalty Card No.</Spantag>
              <Spaninfo>{transData.loyalty}</Spaninfo>
            </Wrapinput>
            <Wrapinput>
              <Spantag>Address</Spantag>
              <Spaninfo>{transData.custAddress}</Spaninfo>
            </Wrapinput>
            <Wrapinput>
              <Spantag>Village</Spantag>
              <Spaninfo>{transData.custVillage}</Spaninfo>
            </Wrapinput>
            <Wrapinput>
              <Spantag>Direction</Spantag>
              <Spaninfo>{transData.direction}</Spaninfo>
            </Wrapinput>
            <Wrapinputhalf>
              <Spantag>Contact No.</Spantag>
              <Spaninfo>{transData.phone}</Spaninfo>
            </Wrapinputhalf>
            <Wrapinputhalf>
              <Spantag>Email</Spantag>
              <Spaninfo>{transData.email}</Spaninfo>
            </Wrapinputhalf>
          </CustomerContainer>
          <ButtonContainer>
            <Buttoncontrol onClick={handleUpdateCart}>
              <Spanbtntag>Update Cart</Spanbtntag>
              <i className='fa fa-floppy-o'></i>
            </Buttoncontrol>
            <Buttoncontrol onClick={handleCancel}>
              <Spanbtntag>Cancel Changes</Spanbtntag>
              <i className='fa fa-times'></i>
            </Buttoncontrol>
            <Buttoncontrol onClick={handlePostUpdate}>
              <Spanbtntag>Post Changes</Spanbtntag>
              <i className='fa fa-check-square-o'></i>
            </Buttoncontrol>
          </ButtonContainer>
        </WrapContainer>
        <WrapContainer>
          <Ordertitle>Order list</Ordertitle>
          {_.isEmpty(transData.Items)
            ? null
            : transData.Items &&
              transData.Items.map((items, index) => {
                return (
                  <OrderList
                    key={index}
                    items={items}
                    count={index}
                    handleChangeCart={handleChangeCart}
                    handleDeleteItem={handleDeleteItem}
                  />
                );
              })}
          <Totalcontainer>
            <Titletotal>TOTAL</Titletotal>
            <SpantotalAmount>
              ${currencyFormat(parseFloat(transData.totalAmount))}
            </SpantotalAmount>
          </Totalcontainer>
        </WrapContainer>
      </Wrapper>
    </>
  );
};

const Totalcontainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px 10px;
  border-top: 1px solid #000;
`;

const Titletotal = styled.span`
  font-size: 1.2rem;
`;

const SpantotalAmount = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
`;

const Ordertitle = styled.span`
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.2;
  padding-bottom: 20px;
`;

const Wrapinput = styled.div`
  width: 100%;
  border: 1px solid #e6e6e6;
  margin-bottom: 10px;
  padding: 10px 20px;
  position: relative;
  border-radius: 10px;
  background-color: #e6e6e6;
`;
const Wrapinputhalf = styled.div`
  width: 48%;
  position: relative;
  border: 1px solid #e6e6e6;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: #e6e6e6;
`;

const Spantag = styled.span`
  font-size: 10px;
  color: #393939;
  line-height: 1.5;
  text-transform: uppercase;
`;
const Spaninfo = styled.span`
  display: block;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 500;
  color: #555;
  line-height: 1.2;
  padding-right: 15px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  min-height: 100vh;
  background: #e6e6e6;
  border-radius: 10px;
`;
const WrapContainer = styled.div`
  display: flex;
  background: #fff;
  margin: auto;
  border-radius: 10px;
  padding: 50px;
  overflow: hidden;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
`;

const SearchInput = styled.input`
  height: 40px;
  display: block;
  width: 100%;
  font-size: 1.2rem;
  line-height: 1.2;
  padding-right: 15px;
  padding-left: 15px;
  border: none;
  outline: none;
`;
const ButtonSubmit = styled.button`
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 34px;
  right: 25px;
  border: none;
  background-color: #fff;
  color: #0054a6;
  cursor: pointer;
  border-radius: 10px;
`;

const Spanbtntag = styled.span`
  padding-right: 10px;
  color: #fff;
  line-height: 1.5;
  text-transform: uppercase;
`;

const Buttoncontrol = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 175px;
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #0054a6;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
`;

const CustomerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default CustomerTransaction;
