import React from 'react';
import Header from '../components/header-group/HeaderGroupStandard';

const Error404 = () => {
  return (
    <>
      <Header />
      <div component='div' className={'errorBackground'}>
        <h1>Sorry, but this page doesn't exist</h1>
        <p>
          It looks like the page your looking for is not available. Please
          return to the home page.
        </p>
      </div>
    </>
  );
};

export default Error404;
