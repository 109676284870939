import React from 'react';

import Header from '../components/header-group/HeaderGroupStandard';
import SignUp from '../components/auth/SignUp';

const SignUpPage = () => {
  return (
    <div>
      <Header />
      <SignUp />
    </div>
  );
};

export default SignUpPage;
