import React from 'react';
// import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  emptyCart,
  updateCart,
  deleteItem,
} from '../store/actions/checkoutAction';
import currencyFormat from '../config/currencyFormat';

// Component
import CartItem from './CartItem';

// Design Use
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  contain: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    padding: '10px 10px',
    maxWidth: 'sm',
    borderTop: '1px solid #000',
  },
  listItem: {
    padding: theme.spacing(1, 0),
    color: 'black',
  },
  total: {
    fontWeight: 700,
    color: 'black',
  },
  title: {
    // marginTop: theme.spacing(2),
    fontWeight: 700,
    color: 'black',
  },
  headerbutton: {
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: '#0054a6',
    borderRadius: '5px',
    border: '0',
    padding: '0 5px',
    color: '#fff',
    fontSize: '.8rem',
    width: '105px',
    cursor: 'pointer',
  },
  updatebutton: {
    display: 'flex',
    justifyContent: 'space-around',
    marginRight: '10px',
    backgroundColor: '#0054a6',
    borderRadius: '5px',
    border: '0',
    padding: '0 5px',
    color: '#fff',
    fontSize: '.8rem',
    width: '105px',
    cursor: 'pointer',
  },
  SpanTag: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      marginBottom: 'auto',
      marginTop: 'auto',
    },
  },
  headertitle: {
    fontSize: '.85rem',
    fontWeight: '300',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
      fontWeight: '500',
    },
  },
}));

const ReviewCart = ({ history }) => {
  const classes = useStyles();
  let cartItems = useSelector((state) => state.checkoutData.product);
  const cartQty = useSelector((state) => state.checkoutData.totalQty);
  const cartAmount = useSelector((state) => state.checkoutData.totalAmount);
  let cartDetail = [];

  let isEmpty = true;
  if (cartQty === 0) {
    isEmpty = !isEmpty;
  }

  const dispatch = useDispatch();

  const handleEmptyCart = () => {
    dispatch(emptyCart());
    history.push('/');
  };

  const handleChangeCart = (info) => {
    const foundIndex = cartDetail.findIndex((e) => e.index === info.index);
    // console.log(foundIndex);
    if (foundIndex === -1) {
      cartDetail.push(info);
    } else {
      cartDetail[foundIndex] = info;
    }
  };

  const handleUpdateCart = () => {
    if (cartDetail.length !== 0) {
      cartDetail.forEach((item, index) => {
        cartItems[item.index].CartQty = item.newCartQty;
        let subtotal = item.newCartQty * cartItems[item.index].SellPrice;
        cartItems[item.index].TotalAmount = parseFloat(subtotal);
      });
      dispatch(updateCart(cartItems));
    }
  };

  const handleDeleteItem = (index) => {
    dispatch(deleteItem(index, cartItems));
  };

  const CartListRow = (dataProduct) => {
    if (dataProduct !== undefined) {
      return dataProduct.map((product, index) => {
        return (
          <CartItem
            key={index}
            product={product}
            count={index}
            handleChangeCart={handleChangeCart}
            handleDeleteItem={handleDeleteItem}
          />
        );
      });
    } else {
      return <h3>Empty Data</h3>;
    }
  };

  return (
    <>
      <Typography className={classes.headertitle} gutterBottom>
        Order summary
      </Typography>
      <div className='order-summary-appbar'>
        <button
          className={classes.headerbutton}
          onClick={() => {
            history.push('/');
          }}
        >
          <i className='fa fa-home faIcon'></i>
          <span className={classes.SpanTag}>Return Home</span>
        </button>
        <div className='order-summary-appbar-sub'>
          <button className={classes.updatebutton} onClick={handleUpdateCart}>
            <i className='fa fa-repeat faIcon'></i>
            <span className={classes.SpanTag}>Update Cart</span>
          </button>
          <button className={classes.headerbutton} onClick={handleEmptyCart}>
            <span className={classes.SpanTag}>Empty Cart</span>
            <i className='fa fa-trash faIcon'></i>
          </button>
        </div>
      </div>
      <div className='cartlist-cotainer'>
        <div className='cartlist-header-title'>
          <label className={'cartlist-header-title-item'}>ITEM</label>
          <label className={'cartlist-header-title-qty'}>QTY</label>
          <label className={'cartlist-header-title-price'}>PRICE</label>
          <label className={'cartlist-header-title-total'}>TOTAL</label>
        </div>
        <div className='cart-item-container'>
          {cartQty === 0 ? (
            <React.Fragment>
              <Typography variant='h6' gutterBottom>
                Cart is Empty!
              </Typography>
              <Typography variant='subtitle1'>
                You may click the continue shopping.
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>{CartListRow(cartItems)}</React.Fragment>
          )}
        </div>
      </div>
      <Container className={classes.contain}>
        <Typography variant='h6'>TOTAL</Typography>
        <span className='cart-item-totalamnt'>
          $ {currencyFormat(parseFloat(cartAmount))}
        </span>
      </Container>

      {/* <div>
        <Button
          variant='contained'
          color='primary'
          onClick={handleNext}
          disabled={!isEmpty}
        >
          Next
        </Button>
      </div> */}
    </>
  );
};

export default ReviewCart;
