import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import currencyFormat from '../config/currencyFormat';

import defaultImg from '../images/no-product-image-available.png';

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const CartItem = ({ product, handleChangeCart, handleDeleteItem, count }) => {
  const cartItemQty = useSelector((state) => state.checkoutData.product[count]);
  const [cartQty, setCartQty] = useState(cartItemQty.CartQty);
  const [subtotal, setSubtotal] = useState(cartItemQty.TotalAmount);

  // console.log(cartItemQty);

  const handleUp = () => {
    let newCartQty = cartQty + 1;
    setCartQty(newCartQty);
    setSubtotal(product.SellPrice * newCartQty);
    let newCartInfo = { index: count, newCartQty };

    handleChangeCart(newCartInfo);
  };

  const handleDown = () => {
    if (cartQty !== 1) {
      let newCartQty = cartQty - 1;
      setCartQty(newCartQty);
      setSubtotal(product.SellPrice * newCartQty);

      let newCartInfo = { index: count, newCartQty };
      handleChangeCart(newCartInfo);
    }
  };

  const inputChange = () => {};

  const handleDelete = () => {
    handleDeleteItem(count);
  };
  return (
    <>
      <div className='cart-item-wrapper'>
        <div className='cart-item-img-container'>
          <img
            className='cart-item-img'
            src={product.Image || defaultImg}
            alt='itemImg'
          />
        </div>
        <div className='cart-item-desc-container'>
          <label className='cart-item-desc'>{product.ItemName}</label>
          <span className='cart-item-itemcode'>#{product.ItemCode}</span>
        </div>
        <div className='cart-item-qty'>
          <button className='cart-item-btn-left' onClick={handleUp}>
            <i className='fa fa-caret-up'></i>
          </button>
          <input type='text' value={cartQty} onChange={inputChange} />
          <button className='cart-item-btn-right' onClick={handleDown}>
            <i className='fa fa-caret-down'></i>
          </button>
        </div>
        <div className='cart-item-label-container'>
          <label className='cart-item-label-price'>
            ${currencyFormat(parseFloat(product.SellPrice))}
          </label>
          <label className='cart-item-label-total'>
            ${currencyFormat(parseFloat(subtotal))}
          </label>
        </div>
        <div className='cart-item-delete'>
          <IconButton onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default CartItem;
