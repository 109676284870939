const initState = {
  authError: null,
};

const authReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case 'LOGIN_ERROR':
      return {
        ...state,
        authError: 'Login failed',
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        authError: null,
      };
    case 'SIGNOUT_SUCCESS':
      return state;
    case 'SIGNUP_SUCCESS':
      return { ...state, authError: null };
    case 'SIGNUP_ERROR':
      console.log('signup error');
      return {
        ...state,
        authError: payload.message,
      };
    default:
      return state;
  }
};

export default authReducer;
