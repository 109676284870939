import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import Modal from 'react-modal';
import ClipLoader from 'react-spinners/ClipLoader';

// Action
// import { sendMail } from '../store/actions/checkoutAction';

// Action Redux
import {
  createCheckOutCOD,
  addServiceFee,
  sendMail,
  sendNodeMailer,
  resetCheckoutData,
} from '../store/actions/checkoutAction';
import { addUser } from '../store/actions/addressFromAction';

import currencyFormat from '../config/currencyFormat';

// import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Checkbox,
  makeStyles,
} from '@material-ui/core';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';

const enhance = compose(
  firestoreConnect((props) => {
    return [{ collection: 'parameters' }];
  }),
  connect((state) => ({
    parameters: state.firestore.ordered.parameters,
  }))
);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: '3px 3px',
  },
  paymentbtn: {
    margin: 0,
    fontSize: '1rem',
    height: '2.5rem',
    padding: '0 20px',
    color: '#fff',
    backgroundColor: '#0054a6',
    borderRadius: '50px',
    cursor: 'pointer',
  },
  cardoption: {
    display: 'flex',
    margin: 'auto',
    width: '100%',
  },
  stripepayment: {
    width: 'auto',
    margin: 'auto',
  },
  modaltitle: {
    marginTop: '20px',
    marginBottom: '20px',
    color: '#dd2c00',
    fontSize: '20px',
  },
  callbackinfo: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '32px',
    // [theme.breakpoints.up('sm')]: {
    //   width: '100%',
    // },
  },
  helpertxt: {
    fontSize: '0.8rem',
    fontStyle: 'italic',
    paddingLeft: '30px',
    color: '#2c4c94',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
  },
  headertitle: {
    fontSize: '0.85rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
      fontWeight: '500',
    },
  },
  radioform: {
    fontSize: '0.5rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
  },
}));

Modal.setAppElement('#root');

const PaymentForm = ({ history }) => {
  const classes = useStyles();
  // const userInfo = useSelector((state) => state.addressForm.userinfo);
  const price = useSelector((state) => state.checkoutData.totalAmount);
  const withFee = useSelector((state) => state.checkoutData.feeCOD);
  const allProducts = useSelector((state) => state.checkoutData.product);
  const authID = useSelector((state) => state.firebase.auth.uid);
  // const isEmpty = useSelector((state) => state.firebase.profile.isEmpty);
  const isLoaded = useSelector((state) => state.firebase.profile.isLoaded);
  const userProfile = useSelector((state) => state.firebase.profile);
  const checkoutProcessError = useSelector((state) => state.checkoutData.error);
  const checkoutProcessErrmsg = useSelector(
    (state) => state.checkoutData.errmsg
  );
  const parameters = useSelector((state) => state.firestore.ordered.parameters);
  const successStatus = useSelector((state) => state.checkoutData.success);
  const completeProcess = useSelector(
    (state) => state.checkoutData.processComplete
  );

  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalEmptyCartIsOpen, setModalEmptyCartIsOpen] = useState(false);
  const [isCard, setIsCard] = useState(false);
  const [seqNo, setSeqNo] = useState(null);
  const [isSignUp, setIsSignUp] = useState(false);
  const [defaultValue, setDefaultValues] = useState({
    cardName: '',
    email: '',
    phone: '',
    address: '',
    village: '',
    loyalty: '',
    direction: '',
    feedback: '',
  });

  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (authID && isLoaded) {
      setDefaultValues({
        cardName: `${userProfile.firstName} ${userProfile.lastName}`,
        email: userProfile.email,
        phone: userProfile.phone,
        address: userProfile.address,
        village: userProfile.village,
        loyalty: userProfile.loyalty,
        direction: userProfile.direction,
        feedback: '',
      });
    }

    if (seqNo === null) {
      axios
        .get('/seqno')
        .then((res) => {
          let foundSeqNo = res.data.seqNo;
          console.log(foundSeqNo);
          setSeqNo(foundSeqNo);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (successStatus) {
      setProcessingTo(false);
      dispatch(resetCheckoutData);
      if (isSignUp) {
        history.push('/signup');
      } else {
        history.push('/success');
      }
    }
  }, [seqNo, successStatus, completeProcess, isLoaded, authID]);

  const handleChangeUserForm = (input) => (e) => {
    setDefaultValues({ ...defaultValue, [input]: e.target.value });
    dispatch(addUser(defaultValue));
  };

  const handleCheckBox = (e) => {
    setIsSignUp(e.target.checked);
  };

  let codFee = 0;
  let moa = 0;
  if (parameters !== undefined) {
    codFee = parameters[0].codfee;
    moa = parameters[0].moa;
  }

  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const cardPayment = async (billingDetails) => {
    return;
    setProcessingTo(true);
    const cardElement = elements.getElement(CardElement);

    try {
      const { data: clientSecret } = await axios.post(
        'https://us-central1-triple-j-online.cloudfunctions.net/createStripePayment',
        {
          amount: Number(price) * 100,
        }
      );

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: billingDetails,
      });

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        console.log(checkoutError);
        return;
      }

      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodReq.paymentMethod.id,
      });

      if (error) {
        setCheckoutError(error.message);
        setProcessingTo(false);
        console.log(checkoutError);
        return;
      }
    } catch (err) {
      setCheckoutError(err);
      // toast(checkoutError, { type: 'error' });
      console.log(checkoutError);
    }
  };

  const otherPayment = (billingDetails, transRef) => {
    // Process payment for COD

    let updatedPrice;
    updatedPrice = withFee ? price + codFee : price;

    if (updatedPrice < moa) {
      setProcessingTo(false);
      // console.log(`${updatedPrice} is less than ${moa}`);
      return;
    }

    let finalCheckoutData = {};

    // Update checkoutproduct remove image property
    allProducts.forEach((i) => {
      delete i.Image;
    });

    finalCheckoutData = {
      transRef,
      Items: allProducts,
      Customer: {
        ...billingDetails,
        customerID: authID !== undefined ? authID : 'GUESTID',
      },
      totalAmount: updatedPrice,
      serviceFee: withFee ? codFee : 0,
      paymentMethod: value,
    };
    // console.log(finalCheckoutData);
    // return;
    /* dispatch(sendNodeMailer(finalCheckoutData));
    return; */
    dispatch(createCheckOutCOD(finalCheckoutData));

    if (checkoutProcessError) {
      setCheckoutError(checkoutProcessErrmsg);
      return;
    } else {
      dispatch(sendNodeMailer(finalCheckoutData));
      setCheckoutError();
      /* console.log('process complete'); */
      return;
    }
  };

  const handleFormSubmit = (ev) => {
    ev.preventDefault();

    setProcessingTo(true);

    let billingDetails = {
      name: defaultValue.cardName,
      email: defaultValue.email,
      phone: defaultValue.phone,
      loyalty: defaultValue.loyalty,
      direction: defaultValue.direction,
      feedback: defaultValue.feedback,
      address: {
        address: defaultValue.address,
        village: defaultValue.village,
      },
    };

    // Validate if cart is empty
    if (allProducts.length === 0) {
      setModalEmptyCartIsOpen(true);
      setProcessingTo(false);
      return;
    }

    // Validate Minumum order amount
    if (moa > price) {
      setProcessingTo(false);
      setModalIsOpen(true);
      return;
    }

    // Generate Transaction Reference Number
    const max = 10;
    const min = 0;

    let result = Math.random() * (max - min) + min;
    let value = result.toString();
    let transNo = seqNo.toString(); //value.substring(9, 17);

    if (value === 'card') {
      cardPayment(billingDetails);
    } else if (value !== 'card') {
      otherPayment(billingDetails, transNo);
    }
  };

  const optionInfo = (selected) => {
    if (selected === 'card') {
      setIsCard(true);
      dispatch(addServiceFee(false));
      setHelperText('Card Payment is still not available');
      setError(true);
      codFee = 0;
    }
    if (selected === 'cod') {
      setIsCard(false);
      codFee = parameters[0].codfee;
      /* setHelperText(
        'You can pay Credit Card over the phone as our customer service representative will call you shortly to confirm and process your order. Please take note of your order confirmation number.'
      ); */
      dispatch(addServiceFee(true));
      /* if (seqNo > 10) {
        dispatch(addServiceFee(true));
      } else {
        dispatch(addServiceFee(false));
      } */
    }
    if (selected === 'pickup') {
      setIsCard(false);
      /* setHelperText(
        'Please present the order confirmation number upon pickup at the store.'
      ); */
      codFee = 0;
      dispatch(addServiceFee(false));
      // Process Order Pickup
    }
  };

  const handleRadioChange = (ev) => {
    setValue(ev.target.value);
    setHelperText(' ');
    setError(false);
    optionInfo(ev.target.value);
  };

  const cardElementOpts = {
    iconStyle: 'solid',
    hidePostalCode: true,
  };

  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      border: '1px solid #000',
      background: '#143454',
    },
  };

  const modalLoading = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      zIndex: '99',
    },
  };

  const override = `
    display: block;
    margin: 0 auto;        
  `;

  const CustomerFYI = () => {
    return (
      <React.Fragment>
        <Typography style={{ marginTop: '10px', fontSize: '.8rem' }}>
          Please be informed we do require a minimum purchase amount of $
          {currencyFormat(moa)}
        </Typography>
      </React.Fragment>
    );
  };

  const StripeCardElement = () => {
    return (
      <CardElement
        options={cardElementOpts}
        onChange={handleCardDetailsChange}
        disabled={true}
      />
    );
  };

  let subtitle = '';
  let content = '';
  let button = '';
  const afterOpenModal = () => {
    subtitle.style.color = '#fff';
    subtitle.style.height = '20px';
    content.style.marginTop = '20px';
    content.style.marginBottom = '20px';
    content.style.color = '#fff';
    button.style.height = '40px';
    button.style.width = '100px';
    button.style.borderRadius = '10px';
    button.style.border = '0';
    button.style.cursor = 'pointer';
    button.style.background = '#0054a6';
    button.style.color = '#fff';
  };

  return (
    <>
      <Modal
        isOpen={isProcessing}
        onRequestClose={() => setModalIsOpen(isProcessing)}
        style={modalLoading}
      >
        <ClipLoader
          size={100}
          color={'#0054a6'}
          loading={isProcessing}
          css={override}
        />
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        onAfterOpen={afterOpenModal}
        style={modalStyle}
        contentLabel='Invalid minimum order amount'
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
          Invalid order amount !
        </h2>
        <p ref={(_content) => (content = _content)}>
          Minimum order required at least ${currencyFormat(moa)}
        </p>
        <div>
          <button
            ref={(_button) => (button = _button)}
            onClick={() => setModalIsOpen(false)}
          >
            Return
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={modalEmptyCartIsOpen}
        onRequestClose={() => setModalEmptyCartIsOpen(false)}
        onAfterOpen={afterOpenModal}
        style={modalStyle}
        contentLabel='Cart is empty'
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Cart is Empty !</h2>
        <p ref={(_content) => (content = _content)}>
          You do not have any items in the cart.
        </p>
        <div>
          <button
            ref={(_button) => (button = _button)}
            onClick={() => setModalEmptyCartIsOpen(false)}
          >
            Return
          </button>
        </div>
      </Modal>

      <span className={classes.headertitle}>Customer Information</span>
      <form onSubmit={handleFormSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              id='cardName'
              label='Full Name'
              placeholder='John Smith'
              fullWidth
              onChange={handleChangeUserForm('cardName')}
              value={defaultValue.cardName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              type='email'
              id='email'
              label='Email'
              placeholder='email@example.com'
              fullWidth
              onChange={handleChangeUserForm('email')}
              value={defaultValue.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              type='phone'
              id='phone'
              label='Phone Number'
              placeholder='(999)555-3333'
              fullWidth
              onChange={handleChangeUserForm('phone')}
              value={defaultValue.phone}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              id='address'
              label='Address'
              placeholder='Street/Apartment Address'
              fullWidth
              onChange={handleChangeUserForm('address')}
              value={defaultValue.address}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              id='village'
              label='Village'
              placeholder='Village Name'
              fullWidth
              onChange={handleChangeUserForm('village')}
              value={defaultValue.village}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id='loyalty'
              label='Loyalty Card'
              placeholder='041271234567'
              fullWidth
              onChange={handleChangeUserForm('loyalty')}
              value={defaultValue.loyalty}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id='direction'
              label='Direction'
              multiline
              rows={4}
              placeholder='Near the hospital'
              variant='outlined'
              fullWidth
              onChange={handleChangeUserForm('direction')}
              value={defaultValue.direction}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id='feedback'
              label='Customer Feedback'
              multiline
              rows={4}
              placeholder='Please send us your feedback and suggestions.'
              variant='outlined'
              fullWidth
              onChange={handleChangeUserForm('feedback')}
              value={defaultValue.feedback}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl
              component='fieldset'
              className={classes.formControl}
              error={error}
            >
              <FormLabel component='legend'>Payment Options</FormLabel>
              <RadioGroup
                name='paymentoption'
                value={value}
                onChange={handleRadioChange}
              >
                <div className={classes.cardoption}>
                  <FormControlLabel
                    value='card'
                    control={<Radio disabled={true} color='primary' />}
                    label='Credit Card'
                  />
                  <div className={classes.stripepayment}>
                    {isCard ? <StripeCardElement /> : <div></div>}
                  </div>
                </div>
                <FormControlLabel
                  value='cod'
                  control={
                    <Radio className={classes.radioform} color='primary' />
                  }
                  label={`Payment by phone + $${currencyFormat(
                    parseFloat(codFee)
                  )} Delivery Fee.
                  Please allow your order to take up to 24 hours to be delivered.
                  `}
                />
                <p>
                  You can pay Credit Card over the phone as our customer service
                  representative will call you shortly to confirm and process
                  your order. Please take note of your order confirmation
                  number.
                </p>
                <FormControlLabel
                  value='pickup'
                  control={<Radio color='primary' />}
                  label='Pickup at store'
                />
                <p>
                  Please present the order confirmation number upon pickup at
                  the store.
                </p>
              </RadioGroup>
            </FormControl>
            <FormHelperText className={classes.helpertxt}>
              {helperText}
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              disabled={true}
              control={
                <Checkbox
                  color='primary'
                  name='saveCustomer'
                  onChange={handleCheckBox}
                  value={isSignUp}
                />
              }
              label='Signup for an account'
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <button
              type='submit'
              className={classes.paymentbtn}
              disabled={isProcessing}
            >
              {isProcessing
                ? 'Processing...'
                : `Place order $ ${
                    withFee
                      ? currencyFormat(parseFloat(price) + parseFloat(codFee))
                      : currencyFormat(parseFloat(price))
                  }`}
            </button>
          </Grid>
        </Grid>
      </form>
      {value !== '' ? <CustomerFYI /> : <div></div>}
    </>
  );
};

export default enhance(PaymentForm);
