import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
// import dotenv from 'dotenv';

// dotenv.config();

// console.log(process.env);
// Initialize Firebase

const config = {
  apiKey: process.env.REACT_APP_fbconfig,
  authDomain: 'triple-j-online.firebaseapp.com',
  databaseURL: 'https://triple-j-online.firebaseio.com',
  projectId: 'triple-j-online',
  storageBucket: 'triple-j-online.appspot.com',
  messagingSenderId: '865752011629',
  appId: '1:865752011629:web:69e129d475ac041f714064',
  measurementId: 'G-TWJ0TZQW0F',
};

firebase.initializeApp(config);

// config firebase with firestore
firebase.firestore();
// firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase;
